import React, { useRef, useEffect } from "react";
import { toast } from "react-hot-toast";
import ReactLoading from "react-loading";
import { useAuth } from "../../../contexts/auth_provider";
import {
  useUpdateChartNode,
  useCreateChartNode,
  useDeleteChartNode,
  useMoveChartNode,
} from "../../../infra/api";
import { ModalNestedItem } from "../../pages/structure/components/modal_nested_item";
import {
  NavItem,
  NavigatorChart,
  SelectibleNavItem,
  NavItemRequest,
} from "../../shared/types";
import { CustomButton } from "../../styles/custom_button";

interface ViewSectionModalProps {
  onClose: () => void;
  item: NavItem;
  onChangeStructure: () => void;
  navigatorStructure?: NavigatorChart;
}

const ViewSectionModal: React.FunctionComponent<ViewSectionModalProps> = ({
  item,
  onChangeStructure,
  navigatorStructure,
}) => {
  const { user } = useAuth();
  const emptyNavItem: NavItem = {
    category: "",
    feedback_link: "",
    is_visible: true,
    name: "",
    minimum_permission_level: "",
    id: "",
    presentation_link: "",
    value: 3,
  };
  const contentRef = useRef<HTMLDivElement>(null);
  const [currentNavItem, setCurrentNavItem] =
    React.useState<SelectibleNavItem | null>(null);
  const [editingNavItem, setEditingNavItem] = React.useState<NavItem>(item);
  const [creatingNavItem, setcreatingNavItem] =
    React.useState<NavItem>(emptyNavItem);
  const [currentMode, setCurrentMode] = React.useState<
    "none" | "editing" | "creating" | "delete" | "moving"
  >("none");
  const {
    mutateAsync: updateNode,
    isLoading: updateIsLoading,
    isError: updateIsError,
  } = useUpdateChartNode();
  const {
    mutateAsync: createNode,
    isLoading: createIsLoading,
    isError: createIsError,
  } = useCreateChartNode();
  const {
    mutateAsync: deleteNode,
    isLoading: deleteIsLoading,
    isError: deleteIsError,
  } = useDeleteChartNode();
  const {
    mutateAsync: moveNode,
    isLoading: moveIsLoading,
    isError: moveIsError,
  } = useMoveChartNode();
  const isError =
    createIsError || updateIsError || deleteIsError || moveIsError;
  const isLoading =
    createIsLoading || updateIsLoading || deleteIsLoading || moveIsLoading;

  const [isDeleteActive, setIsDeleteActive] = React.useState<boolean>(false);

  const editNavItem = React.useCallback((key: string, value: string) => {
    key = editNavItem.arguments;
    setEditingNavItem((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }, []);
  useEffect(() => {
    if (contentRef.current) {
      const contentHeight = contentRef.current.scrollHeight;
      const modalHeight = window.innerHeight * 0.8;
      if (contentHeight > modalHeight) {
        contentRef.current.classList.add("overflow-y-scroll");
      } else {
        contentRef.current.classList.remove("overflow-y-scroll");
      }
    }
  }, []);

  const sendEdition = React.useCallback(
    async (item: NavItem) => {
      const data: NavItem = {
        ...item,
        name: editingNavItem.name,
        is_visible: editingNavItem.is_visible,
        minimum_permission_level: "level_1",
        presentation_link:
          editingNavItem.presentation_link.length > 0
            ? editingNavItem.presentation_link
            : "-",
        feedback_link:
          editingNavItem.feedback_link.length > 0
            ? editingNavItem.feedback_link
            : "-",
      };
      updateNode(data).then(() => {
        toast.success("Section edited with success", {
          duration: 1000,
        });
        onChangeStructure();
      });
    },
    [editingNavItem, onChangeStructure, updateNode]
  );

  const sendCreateChild = React.useCallback(async () => {
    const data: NavItemRequest = {
      parent_id: item.id,
      name: creatingNavItem.name,
      is_visible: creatingNavItem.is_visible,
      minimum_permission_level: "level_1",
      category: item.category,
      presentation_link:
        creatingNavItem.presentation_link.length > 0
          ? creatingNavItem.presentation_link
          : "",
      feedback_link:
        creatingNavItem.feedback_link.length > 0
          ? creatingNavItem.feedback_link
          : "",
    };
    createNode(data).then(() => {
      toast.success("Created child with success", {
        duration: 1000,
      });
      onChangeStructure();
    });
  }, [creatingNavItem, item, onChangeStructure, createNode]);

  const sendDeleteSection = React.useCallback(async () => {
    deleteNode(item.id).then(() => {
      toast.success("Deleted with success", {
        duration: 1000,
      });
      onChangeStructure();
    });
  }, [deleteNode, item.id, onChangeStructure]);

  const sendMoveSection = React.useCallback(async () => {
    if (currentNavItem) {
      moveNode({
        section_id: item.id,
        new_parent_section_id: currentNavItem.id,
      }).then(() => {
        toast.success("Moved with success", {
          duration: 1000,
        });
        onChangeStructure();
      });
    }
  }, [currentNavItem, item.id, moveNode, onChangeStructure]);

  const hasError = () => {
    return (
      isError && (
        <span className="text-xl font-semibold text-red-500">
          Something Wrong Happened, please try again.
        </span>
      )
    );
  };

  return isLoading ? (
    <section
      style={{ width: "80vw", height: "90%" }}
      className=" flex items-center justify-center"
    >
      <ReactLoading type="cylon" color="#000000" height={"10%"} width={"10%"} />
    </section>
  ) : (
    <section
      style={{ width: "80vw", height: "90%", overflowY: "auto" }}
      className=" flex items-start justify-start"
    >
      <div className="flex flex-col items-start justify-center p-5 w-full align-content-start">
        {/* Modal content goes here */}
        <div className="flex items-start gap-6 pt-1 flex-wrap">
          <h1 className="text-2xl font-semibold antialiased uppercase">
            {item.name}
          </h1>
          <div className="flex items-start gap-6">
            <CustomButton
              label={currentMode === "editing" ? "Stop Editing" : "Edit"}
              onClick={() => {
                if (currentMode !== "editing") {
                  setCurrentMode("editing");
                } else {
                  setEditingNavItem(item);
                  setCurrentMode("none");
                }
              }}
            />
            <CustomButton
              label="Create child"
              onClick={() =>
                currentMode !== "creating"
                  ? setCurrentMode("creating")
                  : setCurrentMode("none")
              }
            />
            <CustomButton
              label="Delete"
              onClick={() =>
                currentMode !== "delete"
                  ? setCurrentMode("delete")
                  : setCurrentMode("none")
              }
            />
            {(user?.role === "admin" || user?.role === "leader") && (
              <CustomButton
                label="Move section"
                onClick={() =>
                  currentMode !== "moving"
                    ? setCurrentMode("moving")
                    : setCurrentMode("none")
                }
              />
            )}
          </div>
        </div>

        {currentMode === "editing" && (
          <div className="w-full bg-black mt-10 pl-2">
            <label className="text-xl text-white font-semibold antialiased mt-10 ">
              Editing
            </label>
          </div>
        )}

        {currentMode === "none" && (
          <div className="w-full bg-black mt-10 pl-2">
            <label className="text-xl text-white font-semibold antialiased mt-10 ">
              View Section
            </label>
          </div>
        )}
        {currentMode === "creating" && (
          <div className="w-full bg-black mt-10 pl-2">
            <label className="text-xl text-white font-semibold antialiased mt-10 ">
              Creating Child
            </label>
          </div>
        )}
        {currentMode === "delete" && (
          <div className="w-full bg-red-500 mt-10 pl-2">
            <label className="text-2xl text-black font-semibold antialiased mt-10 ">
              WARNING: DELETING
            </label>
          </div>
        )}
        {(currentMode === "editing" || currentMode === "none") && (
          <>
            <label className="text-lg font-semibold antialiased mt-10 ">
              Name
            </label>
            <input
              type="text"
              className="border rounded-md py-2 px-3 mb-6 w-300"
              value={editingNavItem.name}
              disabled={!(currentMode === "editing")}
              onChange={(e) =>
                setEditingNavItem({ ...editingNavItem, name: e.target.value })
              }
            />
            <div className="flex items-center justify-center gap-x-4">
              <label className="text-lg font-semibold antialiased">
                Is Visible
              </label>
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 accent-pink-600 "
                checked={editingNavItem.is_visible}
                disabled={!(currentMode === "editing")}
                onChange={(e) =>
                  setEditingNavItem({
                    ...editingNavItem,
                    is_visible: e.target.checked,
                  })
                }
              />
            </div>
            <label className="text-lg font-semibold antialiased mt-6">
              Presentation Link
            </label>
            <input
              type="text"
              className="border rounded-md py-2 px-3 mb-6"
              value={editingNavItem.presentation_link}
              disabled={!(currentMode === "editing")}
              onChange={(e) =>
                setEditingNavItem({
                  ...editingNavItem,
                  presentation_link: e.target.value,
                })
              }
            />

            <label className="text-lg font-semibold antialiased">
              Feedback Link
            </label>
            <input
              type="text"
              className="border rounded-md py-2 px-3 mb-10"
              value={editingNavItem.feedback_link}
              disabled={!(currentMode === "editing")}
              onChange={(e) =>
                setEditingNavItem({
                  ...editingNavItem,
                  feedback_link: e.target.value,
                })
              }
            />
            {currentMode === "editing" && (
              <div className="flex items-center justify-center gap-x-6">
                <CustomButton
                  label="Save changes"
                  onClick={() => sendEdition(item)}
                />
                <CustomButton
                  tailwindColor="bg-red-500"
                  label="Discart changes"
                  onClick={() => {
                    setEditingNavItem(item);
                  }}
                />
              </div>
            )}
          </>
        )}
        {currentMode === "creating" && (
          <>
            <label className="text-lg font-semibold antialiased mt-10 ">
              Name
            </label>
            <input
              type="text"
              className="border rounded-md py-2 px-3 mb-6 w-300"
              value={creatingNavItem.name}
              onChange={(e) =>
                setcreatingNavItem({ ...creatingNavItem, name: e.target.value })
              }
            />
            <div className="flex items-center justify-center gap-x-4">
              <label className="text-lg font-semibold antialiased">
                Is Visible
              </label>
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-green-600  "
                checked={creatingNavItem.is_visible}
                onChange={(e) =>
                  setcreatingNavItem({
                    ...creatingNavItem,
                    is_visible: e.target.checked,
                  })
                }
              />
            </div>
            <label className="text-lg font-semibold antialiased mt-6">
              Presentation Link
            </label>
            <input
              type="text"
              className="border rounded-md py-2 px-3 mb-6"
              value={creatingNavItem.presentation_link}
              onChange={(e) =>
                setcreatingNavItem({
                  ...creatingNavItem,
                  presentation_link: e.target.value,
                })
              }
            />
            <label className="text-lg font-semibold antialiased">
              Feedback Link
            </label>
            <input
              type="text"
              className="border rounded-md py-2 px-3 mb-10"
              value={creatingNavItem.feedback_link}
              onChange={(e) =>
                setcreatingNavItem({
                  ...creatingNavItem,
                  feedback_link: e.target.value,
                })
              }
            />
            <div className="flex items-center justify-center gap-x-6">
              <CustomButton
                tailwindColor="bg-blue-600"
                label={`Create child`}
                onClick={() => sendCreateChild()}
              />
              <CustomButton
                tailwindColor="bg-red-500"
                label="Discart changes"
                onClick={() => {
                  setcreatingNavItem(emptyNavItem);
                }}
              />
            </div>
          </>
        )}
        {currentMode === "delete" && (
          <div className="flex flex-col items-center  w-full">
            <span className="text-black font-semibold mt-20 text-xl">
              Deleting this section will delete all the Section Children with
              it.
            </span>
            <span className="text-black font-normal mt-20 text-xl">
              This operation is irreversible
            </span>
            <div className="mt-20 ml-20" />
            {isDeleteActive ? (
              <CustomButton
                tailwindColor="bg-red-500 mt-20"
                label="I understand, delete it"
                onClick={() => sendDeleteSection()}
              />
            ) : (
              <CustomButton
                label="I Agree"
                onClick={() => {
                  setIsDeleteActive(true);
                }}
              />
            )}
          </div>
        )}
        {currentMode === "moving" && (
          <div className="flex flex-col items-center  w-full">
            <span className="text-black font-semibold mt-20 text-xl">
              This will move this section and all it's children
            </span>
            <div className="mt-5 ml-5" />
            {currentNavItem && (
              <CustomButton
                label={`Move to ${currentNavItem.name}`}
                onClick={() => sendMoveSection()}
              />
            )}
            <div className="mt-10 ml-20" />
            {navigatorStructure &&
              navigatorStructure.children.map(
                (thisItem) =>
                  thisItem.id !== item.id && (
                    <ModalNestedItem
                      item={thisItem}
                      setCurrentNavItem={setCurrentNavItem}
                      currentNavItem={currentNavItem ?? null}
                      key={thisItem.id}
                      showStatus={false}
                      movingItemId={item.id}
                    />
                  )
              )}
          </div>
        )}
        {hasError()}
      </div>
    </section>
  );
};

export { ViewSectionModal };
