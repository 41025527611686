import React, { useState, useEffect } from "react";
import { BasePage } from "../base";
import ReactLoading from "react-loading";
import {
  useDeleteNews,
  useGetNewsRadarData,
  useGetKanbanRSSNews,
  useDragNewsCard,
  DragCardRequest,
  useCreateNewsCard,
  CreateCardRequest,
} from "../../../infra/api";

import { formatDateTime } from "../../../utils/formatter";
import { NewsItem } from "../../../utils/types/news-radar";
import {
  FaArrowDown,
  FaArrowUp,
  FaEdit,
  FaSearch,
  FaTrash,
} from "react-icons/fa";

import toast from "react-hot-toast";
import KanbanBoard from "../../components/kanban/kanban_board";
import { DropResult, ResponderProvided } from "react-beautiful-dnd";
import { useWebSocketNews } from "../../../infra/ws/news_rs";
import { buildDataObject } from "./utils/build_kanban_data";
import { KanbanData, TKanbanCard } from "../../../utils/utils";
import {
  ConfirmationModal,
  CreateNewsModal,
  EditNewsModal,
  Modal,
  ViewNewsCategoriesModal,
  ViewNewsTagsModal,
} from "../../components";
import { CreateNewsFromRSSModal } from "../../components/modals/create_news_from_rss_modal";

type TPAGE_STATE = "kanban" | "default";
interface NewsItemProps {
  item: NewsItem;
}
const NewsPage: React.FC = () => {
  const [showTagsModal, setShowTagsModal] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showIFrameModal, setShowIFrameModal] = React.useState(false);
  const [showCategoriesModal, setShowCategoriesModal] = React.useState(false);
  const [showCreateNewsModal, setShowCreateNewsModal] = React.useState(false);
  const [showEditNewsModal, setShowEditNewsModal] = React.useState(false);
  const [currentDeletionId, setCurrentDeletionId] = React.useState("");
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [currentNews, setCurrentNews] = React.useState<NewsItem | undefined>(
    undefined
  );
  const [pageState, setPageState] = React.useState<TPAGE_STATE>("default");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchResults, setSearchResults] = useState<NewsItem[]>([]);
  const MENU_ITEMS: Array<{ title: string; onClick: () => void }> = [
    {
      onClick: () =>
        setPageState((state) => (state === "default" ? "kanban" : "default")),
      title: pageState === "default" ? "Go to Kanban" : "Go to Default",
    },
    { onClick: () => setShowCreateNewsModal(true), title: "Create News" },
    { onClick: () => setShowTagsModal(true), title: "Tags" },
    { onClick: () => setShowCategoriesModal(true), title: "Categories" },
  ];
  const handleCloseModal = React.useCallback(() => {
    setShowTagsModal(false);
    setShowCategoriesModal(false);
    setShowCreateNewsModal(false);
    setShowEditNewsModal(false);
    setShowDeleteConfirmationModal(false);
    setShowIFrameModal(false);
  }, []);
  const { isLoading: newsLoading, data, isRefetching } = useGetNewsRadarData();
  const { mutateAsync: deleteNews, isLoading: deletionIsLoading } =
    useDeleteNews();

  let isLoading = newsLoading || isRefetching || deletionIsLoading;

  useEffect(() => {
    if (data && data.length > 0) {
      const results = data.filter((nw: NewsItem) =>
        nw.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchResults(results);
    }
  }, [searchTerm, data]);

  const sendDeleteNews = React.useCallback(
    async (newsId: string) => {
      deleteNews(newsId).then(() => {
        toast.success("Deleted News with success", {
          duration: 1000,
        });
        handleCloseModal();
      });
    },
    [deleteNews, handleCloseModal]
  );

  const NewsComponent: React.FunctionComponent<NewsItemProps> = ({ item }) => {
    const [isHovered, setIsHovered] = React.useState(false);

    return (
      <div
        className="relative w-full flex flex-col items-start justify-center px-4 py-2 bg-gray-200 m-2 rounded-xl cursor-pointer hover:bg-gray-500"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isHovered && (
          <div
            className="bg-white flex flex-row 
          items-center justify-center absolute z-50 
          backdrop-blur w-fit h-fit gap-2 self-center rounded-md"
          >
            <div
              className="flex items-center justify-start hover:bg-yellow-200 cursor-pointer px-2"
              onClick={() => {
                setCurrentNews(item);
                setShowEditNewsModal(true);
              }}
            >
              <FaEdit className="p-4 text-black z-50" size={50} />
              <span>Edit </span>
            </div>
            <div
              className="flex items-center justify-start hover:bg-red-200 cursor-pointer  pr-2"
              onClick={() => {
                setCurrentDeletionId(item.id);
                setShowDeleteConfirmationModal(true);
              }}
            >
              <FaTrash className="p-4 text-red-500 z-50" size={50} />
              <span>Delete </span>
            </div>
          </div>
        )}
        <span className="font-semibold">{item.title}</span>
        <span>{item.description}</span>
        <span className="self-end mt-2 italic">
          {formatDateTime(item.notice_date)}
        </span>
      </div>
    );
  };

  const KanbanView = () => {
    const { isLoading: loadingRSS, data: rssNews } = useGetKanbanRSSNews();
    const { columnsData, columnLoading } = useWebSocketNews();
    const [showSendNewsRSS, setShowSendNewsRSS] =
      React.useState<boolean>(false);
    const [filterTerm, setFilterTerm] = React.useState("");
    const [filterIsAscending, setFilterIsAscending] = React.useState(true);
    const [currentKanbanCard, setCurrentKanbanCard] =
      React.useState<TKanbanCard | null>(null);
    const [currentColumn, setCurrentColumn] = React.useState<string | null>(
      null
    );
    const [currentKanbanNews, setCurrentKanbanNews] =
      React.useState<NewsItem | null>(null);
    const { mutateAsync: dragMutation, isLoading: dragIsLoading } =
      useDragNewsCard();
    const { mutateAsync: createCardMutation } = useCreateNewsCard();
    const isKanbanEmpty = (data: KanbanData): boolean => {
      return Object.values(data.columns).every(
        (column) => column.cardIds.length === 0
      );
    };

    const isLoading = loadingRSS || columnLoading;
    var builtData: KanbanData | undefined;
    if (rssNews) {
      builtData = buildDataObject(
        rssNews,
        columnsData,
        filterTerm,
        filterIsAscending
      );
    }
    const [kanbanData, setKanbanData] = React.useState<KanbanData | undefined>(
      builtData ?? undefined
    );
    const sendDrag = React.useCallback(
      async (drag: DragCardRequest) => {
        dragMutation(drag).then(() => {
          toast.success("Moved with success", {
            duration: 1000,
          });
        });
      },
      [dragMutation]
    );
    // Creation should be possible only to column 1 to column 2.
    const sendCreation = React.useCallback(
      async (create: CreateCardRequest) => {
        createCardMutation(create).then(() => {
          toast.success("Moved with success", {
            duration: 1000,
          });
        });
      },
      [createCardMutation]
    );

    const validateDrag = ({
      from_column,
      to_column,
    }: {
      from_column: string;
      to_column: string;
    }): boolean => {
      if (from_column === "column-1" && to_column !== "column-2") {
        toast.error(
          "Drag not allowed. From first column you need to drag it to second column at first.",
          { duration: 5000 }
        );
        return false;
      }
      if (from_column === to_column) {
        toast.error("Reordering not allowed.");
        return false;
      }
      if (to_column === "column-1") {
        toast.error("Moving to first column is not allowed.");
        return false;
      }

      return true;
    };
    const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
      if (kanbanData) {
        const { destination, source, draggableId } = result;

        if (!destination) {
          return;
        }

        if (
          source.droppableId === destination.droppableId &&
          source.index === destination.index
        ) {
          return;
        }

        const startColumn =
          kanbanData.columns[
            source.droppableId as keyof typeof kanbanData.columns
          ];
        const finishColumn =
          kanbanData.columns[
            destination.droppableId as keyof typeof kanbanData.columns
          ];

        if (
          !validateDrag({
            from_column: startColumn.id,
            to_column: finishColumn.id,
          })
        ) {
          return;
        }

        const startCardIds = Array.from(startColumn.cardIds);
        const [removedCardId] = startCardIds.splice(source.index, 1);
        const newStartColumn = {
          ...startColumn,
          cardIds: startCardIds,
        };

        const finishCardIds = Array.from(finishColumn.cardIds);
        finishCardIds.splice(destination.index, 0, removedCardId);

        const newFinishColumn = {
          ...finishColumn,
          cardIds: finishCardIds,
        };

        const newData = {
          ...kanbanData,
          columns: {
            ...kanbanData.columns,
            [newStartColumn.id]: newStartColumn,
            [newFinishColumn.id]: newFinishColumn,
          },
        };

        setKanbanData(newData);
        // Create card if column 1 to column 2
        if (startColumn.id === "column-1" && finishColumn.id === "column-2") {
          const draggedCard = Object.values(kanbanData.cards).find(
            (card) => card.id === draggableId
          );
          console.log(kanbanData.cards);

          if (draggedCard) {
            const createRequestData: CreateCardRequest = {
              content: draggedCard.content,
              description: draggedCard.description,
              guid: draggedCard.guid ?? "",
              link: draggedCard.link ?? "",
              publishedAt: draggedCard.notice_date ?? "",
              rss_card_id: draggedCard.id,
              title: draggedCard.title,
            };
            sendCreation(createRequestData);
          } else {
            toast.error("There's an error finding the card");
          }
        } else {
          const dragRequestData: DragCardRequest = {
            card_id: draggableId,
            from_column: startColumn.id.charAt(startColumn.id.length - 1),
            to_column: finishColumn.id.charAt(finishColumn.id.length - 1),
          };

          sendDrag(dragRequestData);
        }
      }
    };

    useEffect(() => {
      if (rssNews) {
        const builtData = buildDataObject(
          rssNews,
          columnsData,
          filterTerm,
          filterIsAscending
        );
        setKanbanData(builtData);
      }
    }, [rssNews, columnsData, filterTerm, filterIsAscending]);

    return (
      <div className="w-full flex" style={{ height: "85vh" }}>
        {currentKanbanNews && currentKanbanCard && currentColumn && (
          <Modal
            show={showSendNewsRSS}
            onClose={() => {
              setShowSendNewsRSS(false);
            }}
          >
            <CreateNewsFromRSSModal
              onCreate={() => {}}
              news={currentKanbanNews}
              card_id={currentKanbanCard.id}
              from_column={currentColumn}
            />
          </Modal>
        )}
        {isLoading && (
          <div className="w-full h-full flex items-center justify-center">
            <ReactLoading
              type="cylon"
              color="#000000"
              height={"10%"}
              width={"10%"}
            />
          </div>
        )}
        {dragIsLoading && (
          <div className="w-full h-full flex items-center justify-center absolute z-50">
            <ReactLoading
              type="cylon"
              color="#000000"
              height={"10%"}
              width={"10%"}
            />
          </div>
        )}
        {kanbanData && !isLoading && (
          <div className="w-full flex mt-20" style={{ height: "80vh" }}>
            {isKanbanEmpty(kanbanData) ? (
              <h2 className="text-xl font-bold antialising mb-10 ml-10 mt-10">
                Not found
              </h2>
            ) : (
              <KanbanBoard
                data={kanbanData}
                onDragEnd={onDragEnd}
                onCardClick={(card) => {
                  setShowIFrameModal(true);
                }}
                onSendClick={(news, card, from_col) => {
                  setCurrentKanbanCard(card);
                  setCurrentColumn(from_col);
                  setCurrentKanbanNews(news);
                  setShowSendNewsRSS(true);
                }}
              />
            )}
          </div>
        )}
        {kanbanData && !isLoading && (
          <div className="flex items-center justify-start absolute mb-10 w-full bg-gray-200 p-2">
            <div className="relative ml-4">
              <input
                type="text"
                value={filterTerm}
                onChange={(e) => setFilterTerm(e.target.value)}
                placeholder="Search"
                className="w-full px-4 py-2 pl-10 rounded-md border"
              />
              <span className="absolute left-2 top-3.5 pointer-events-none">
                <FaSearch color="#888" size={16} />
              </span>
            </div>
            <div
              className="hover:bg-blue-100 px-2 py-1 cursor-pointer mr-2 rounded"
              onClick={() => {
                if (filterIsAscending) {
                  setFilterIsAscending(false);
                } else {
                  setFilterIsAscending(true);
                }
              }}
            >
              {filterIsAscending ? (
                <span className="flex items-center justify-center gap-1 text-sm  text-gray-500 antialiased">
                  Ascending
                  <FaArrowUp />
                </span>
              ) : (
                <span className="flex items-center justify-center gap-1 text-sm  text-gray-500 antialiased">
                  Descending
                  <FaArrowDown />
                </span>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <BasePage>
      <div className="dv-structure h-screen">
        <div className=" flex-row flex items-center justify-between mb-6 ">
          <Modal show={showTagsModal} onClose={handleCloseModal}>
            <ViewNewsTagsModal />
          </Modal>
          {/* <Modal show={showIFrameModal} onClose={handleCloseModal}>
            <IFrameModal link={"https://instagram.com"} />
          </Modal> */}
          <Modal show={showCategoriesModal} onClose={handleCloseModal}>
            <ViewNewsCategoriesModal />
          </Modal>
          <Modal show={showCreateNewsModal} onClose={handleCloseModal}>
            <CreateNewsModal onCreate={handleCloseModal} />
          </Modal>
          <Modal show={showDeleteConfirmationModal} onClose={handleCloseModal}>
            <ConfirmationModal
              onClose={handleCloseModal}
              type="important"
              confirmButtonText="I understand"
              message="You're going to delete this content. This operation is irreversible!"
              callback={(v) => {
                if (v) {
                  if (currentDeletionId !== "")
                    sendDeleteNews(currentDeletionId);
                }
              }}
            />
          </Modal>
          {currentNews && (
            <Modal show={showEditNewsModal} onClose={handleCloseModal}>
              <EditNewsModal news={currentNews} onUpdate={handleCloseModal} />
            </Modal>
          )}
          <h1 className="text-2xl font-semibold antialiased uppercase pr-2 self-center">
            News Manager
          </h1>
          <div className="flex">
            {MENU_ITEMS.map((el) => (
              <div
                key={el.title}
                className="flex items-center justify-center rounded-md  w-36 bg-black m-4 px-4 py-2 cursor-pointer hover:bg-gray-500"
                onClick={el.onClick}
              >
                <span className="text-white">{el.title}</span>
              </div>
            ))}
          </div>
        </div>
        {isLoading || !data ? (
          <section
            className="w-fill  flex align-center justify-center "
            style={{
              height: 500,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ReactLoading
              type="cylon"
              color="#000000"
              height={"10%"}
              width={"10%"}
            />
          </section>
        ) : (
          <section className="flex flex-col items-start justify-start">
            {pageState === "default" && (
              <div className="flex flex-col max-h-screen">
                <span className="text-2xl font-semibold antialiased uppercase pr-2 mt-4">
                  News
                </span>
                <div className="relative ml-2 mt-4 mb-2">
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search"
                    className="w-2/4 px-4 py-2 pl-10 rounded-md border"
                  />
                  <span className="absolute left-2 top-3.5 pointer-events-none">
                    <FaSearch color="#888" size={16} />
                  </span>
                </div>
                <div className="w-full overflow-y-auto">
                  {searchResults.map((nw: NewsItem) => (
                    <div className="w-2/4" key={nw.id}>
                      <NewsComponent item={nw} />
                    </div>
                  ))}
                </div>
              </div>
            )}
            {pageState === "kanban" && <KanbanView />}
          </section>
        )}
      </div>
    </BasePage>
  );
};

export { NewsPage };
