import React, { createContext, useContext, useState } from "react";

interface ChartContextType {
  kind: string;
  setKind: (value: string) => void;
}

const defaultContext: ChartContextType = {
  kind: "main",
  setKind: () => {},
};

const ChartContext = createContext<ChartContextType>(defaultContext);

const ChartProvider = ({ children }: { children: React.ReactNode }) => {
  const [kind, setKind] = useState<string>("main");

  const value = {
    kind,
    setKind,
  };

  return (
    <ChartContext.Provider value={value}>{children}</ChartContext.Provider>
  );
};

const useChart = () => useContext(ChartContext);

export { ChartProvider, useChart };
