import {
  removeAuthTokens,
  loginUser,
  getAccessToken,
  getRefreshToken,
  getUser,
  signoutUser,
} from "../auth/auth";
import { User } from "../utils/types/user";
import React, { createContext, useContext, useState } from "react";
import { toast } from "react-hot-toast";

const AuthContext = createContext({
  isLoggedIn: false,
  signin: async (_: string, __: string) => {},
  signout: () => {},
  user: null as User | null,
});

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState<User | null>(getUser());
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_token, setToken] = useState<string | null>(getAccessToken());
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_refreshToken, setRefreshToken] = useState<string | null>(getRefreshToken());

  React.useEffect(() => {
    const accessToken = getAccessToken();
    const refreshToken = getRefreshToken();
    const user = getUser();
    setUser(user);
    if (accessToken && refreshToken) {
      setIsLoggedIn(true);
      setToken(accessToken);
      setRefreshToken(refreshToken);
    }
  }, []);

  const signin = async (email: string, password: string) => {
    try {
      const loginResponse = await loginUser(email, password);
      if (loginResponse.user) {
        setUser(loginResponse.user);
      }
      if (loginResponse.success) {
        setIsLoggedIn(true);
      } else {
        toast.error(
          `Error - Something went wrong in the login. Please try again`
        );
        throw new Error(loginResponse.error);
      }
    } catch (error: unknown) {
      toast.error(
        `Error - Something went wrong in the login request. Please contact support`
      );

      throw error;
    }
  };

  const signout = () => {
    removeAuthTokens();
    setIsLoggedIn(false);
    setUser(null);
    signoutUser();
  };

  const value = {
    isLoggedIn,
    signin,
    signout,
    user,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
