import React, { useState } from "react";
import { NavItem } from "../../shared/types";
import { BasePage } from "../base";
import { Container } from "./style";
import ReactLoading from "react-loading";
import { NestedItem } from "./components/nested_item";
import { ActionItem } from "./components/action_item";
import { ConfirmationModal } from "../../components/modals/confirmation_modal";
import toast from "react-hot-toast";
import {
  useCreateChartVersion,
  useDeleteChartNode,
  useGetChart,
  useGetChartKindsList,
  useGetChartVersions,
  useGetContentStatus,
} from "../../../infra/api";
import { CustomButton } from "../../styles/custom_button";
import { SunburstPreview } from "../../components/sunburst";
import { useAuth } from "../../../contexts/auth_provider";
import { FaCompressArrowsAlt, FaSyncAlt } from "react-icons/fa";
import { useChart } from "../../../contexts/chart_context";
import {
  CreateBaseNodeModal,
  Modal,
  ViewContentModal,
  ViewSectionModal,
} from "../../components";

const StructurePage: React.FunctionComponent = () => {
  const { user } = useAuth();
  const [currentNavItem, setCurrentNavItem] = useState<NavItem | null>(null);
  const [selectedVersion, setSelectedVersion] = useState<string>("0");
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showContentModal, setShowContentModal] = useState(false);
  const [showCreateBaseNodeModal, setShowCreateBaseNodeModal] = useState(false);
  const [isPreview, setIsPreview] = useState<boolean>(false);
  const [expandedItems, setExpandedItems] = useState(new Set<string>());
  const { setKind, kind } = useChart();
  const {
    isLoading: versionIsLoading,
    data,
    isRefetching,
    refetch,
  } = useGetChart(selectedVersion);
  const {
    isLoading: statusIsLoading,
    data: statusData,
    isRefetching: statusIsRefetching,
  } = useGetContentStatus();
  const { data: versionData } = useGetChartVersions();
  const { data: kindsList } = useGetChartKindsList();
  const { mutateAsync: deleteNode } = useDeleteChartNode();
  const { mutateAsync: createVersion, isLoading: createIsLoading } =
    useCreateChartVersion();
  let isLoading =
    versionIsLoading ||
    isRefetching ||
    statusIsLoading ||
    statusIsRefetching ||
    createIsLoading;

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleOpenConfirmationModal = () => {
    setShowConfirmationModal(true);
  };

  const handleOpenContentModal = () => {
    setShowContentModal(true);
  };
  const handleOpenCreateBaseNodeModal = () => {
    setShowCreateBaseNodeModal(true);
  };

  const handleCloseModal = React.useCallback(() => {
    setShowModal(false);
    setShowConfirmationModal(false);
    setShowContentModal(false);
    setCurrentNavItem(null);
    setShowCreateBaseNodeModal(false);
  }, []);

  const onChangeStructure = React.useCallback(() => {
    handleCloseModal();
  }, [handleCloseModal]);

  const getVersions = () => {
    if (versionData !== undefined) {
      var options = versionData.map((versionChart) => {
        return (
          <option
            onSelect={(e) => console.log(e)}
            key={versionChart.id}
            value={versionChart.id}
            style={{ width: 200 }}
          >{`V${versionChart.version}`}</option>
        );
      });
      options.unshift(
        <option key={0} value={0} style={{ width: 200 }}>
          {"Current version"}
        </option>
      );
      return options;
    }
    return (
      <option value={0} style={{ width: 400 }}>
        {"Edit version"}
      </option>
    );
  };
  const getKinds = () => {
    if (kindsList !== undefined) {
      var options = kindsList.map((versionChart) => {
        return (
          <option
            onSelect={(e) => console.log(e)}
            key={versionChart.kind}
            value={versionChart.kind}
            style={{ width: 200 }}
          >{`${versionChart.kind}`}</option>
        );
      });

      return options;
    }
    return (
      <option value={0} style={{ width: 400 }}>
        {"Kinds"}
      </option>
    );
  };

  const sendDeleteSection = React.useCallback(async () => {
    handleCloseModal();
    deleteNode(currentNavItem!.id)
      .then(() => {
        toast.success("Deleted with success", {
          duration: 1000,
        });
        onChangeStructure();
      })
      .catch(() => {
        toast.error("Error - Something wrong happened", {
          duration: 1000,
        });
      });
  }, [currentNavItem, deleteNode, handleCloseModal, onChangeStructure]);

  return (
    <BasePage>
      <Container className="dv-structure">
        {isPreview ? (
          <section className="bg-black">
            <div className="flex flex-row items-center justify-start gap-6 bg-black p-8">
              <CustomButton
                label="Exit Preview"
                onClick={() => setIsPreview(false)}
                tailwindColor="bg-white"
                textTailwindColor="text-black"
              />
              <select
                defaultValue={0}
                onChange={(e) => {
                  setSelectedVersion(e.target.value);
                  onChangeStructure();
                }}
                className="bg-white text-black text-sm rounded-lg font-semibold antialising  p-3 cursor-pointer h-min "
              >
                {getVersions()}
              </select>
            </div>
            <div className="bg-black" style={{ height: "100%" }}>
              <SunburstPreview version={selectedVersion} />
            </div>
          </section>
        ) : (
          <div className="flex flex-row ">
            {currentNavItem && (
              <Modal show={showModal} onClose={handleCloseModal}>
                <ViewSectionModal
                  onClose={handleCloseModal}
                  item={currentNavItem}
                  onChangeStructure={onChangeStructure}
                  navigatorStructure={data}
                />
              </Modal>
            )}
            {currentNavItem && (
              <Modal show={showCreateBaseNodeModal} onClose={handleCloseModal}>
                <CreateBaseNodeModal
                  onClose={handleCloseModal}
                  onChangeStructure={onChangeStructure}
                  navigatorStructure={data}
                />
              </Modal>
            )}
            {currentNavItem && (
              <Modal show={showContentModal} onClose={handleCloseModal}>
                <ViewContentModal
                  onClose={handleCloseModal}
                  item={currentNavItem}
                  onChangeStructure={onChangeStructure}
                  chart={data}
                />
              </Modal>
            )}
            {currentNavItem && (
              <Modal show={showConfirmationModal} onClose={handleCloseModal}>
                <ConfirmationModal
                  onClose={handleCloseModal}
                  type="important"
                  confirmButtonText="I understand, delete it"
                  message="You're going to delete this section. This operation is irreversible !"
                  callback={(v) => {
                    if (v) {
                      sendDeleteSection();
                    }
                  }}
                />
              </Modal>
            )}
            {currentNavItem && selectedVersion === "0" && (
              <ActionItem
                item={currentNavItem}
                onChangeStructure={onChangeStructure}
                onClickViewSection={handleOpenModal}
                onClickDelete={() => handleOpenConfirmationModal()}
                onClickContent={() => handleOpenContentModal()}
                onClickCreateBaseNode={() => handleOpenCreateBaseNodeModal()}
              />
            )}
            <div className="flex flex-col w-full">
              <div className="pt-16 flex-row flex items-center justify-between mb-6 ">
                <h1 className="text-2xl font-semibold antialiased uppercase pr-2 self-center">
                  Structure Manager
                </h1>
                <div className="flex flex-row items-center justify-end h-min gap-6 ">
                  <div
                    className="cursor-pointer p-2 hover:bg-gray-500 hover:text-white hover:rounded-full"
                    title="Refresh"
                    onClick={function () {
                      setCurrentNavItem(null);
                      setExpandedItems(new Set<string>());
                      return refetch();
                    }}
                  >
                    <FaSyncAlt size={25} />
                  </div>
                  <div
                    className="cursor-pointer p-2 hover:bg-gray-500 hover:text-white hover:rounded-full"
                    title="Collapse"
                    onClick={() => setExpandedItems(new Set<string>())}
                  >
                    <FaCompressArrowsAlt size={25} />
                  </div>
                  <CustomButton
                    label="Preview"
                    onClick={() => setIsPreview(true)}
                  />
                  {selectedVersion === "0" &&
                    !isLoading &&
                    data &&
                    (user?.role === "admin" || user?.role === "leader") &&
                    kind === "main" && (
                      <CustomButton
                        label="Save this version"
                        title="Saving the version means that you will save the current version that you're working on. Creating a view of the Navigator state in this time."
                        onClick={() => {
                          createVersion();
                        }}
                      />
                    )}
                  <select
                    defaultValue={0}
                    onChange={(e) => {
                      setSelectedVersion(e.target.value);
                      onChangeStructure();
                    }}
                    className="bg-black text-white text-sm rounded-lg font-semibold antialising  p-2.5 cursor-pointer h-min "
                  >
                    {getVersions()}
                  </select>
                  <select
                    defaultValue={kind}
                    onChange={(e) => {
                      setKind(e.target.value);
                      onChangeStructure();
                    }}
                    className="bg-black text-white text-sm rounded-lg font-semibold antialising  p-2.5 cursor-pointer h-min "
                  >
                    {getKinds()}
                  </select>
                </div>
              </div>
              {isLoading || !data ? (
                <section
                  className="w-fill  flex align-center justify-center "
                  style={{
                    height: 500,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ReactLoading
                    type="cylon"
                    color="#000000"
                    height={"10%"}
                    width={"10%"}
                  />
                </section>
              ) : (
                <section className="flex">
                  <div className="flex-1 p-4">
                    <h2
                      className="text-xl underline  antialiased "
                      style={{ marginBottom: 32 }}
                    >
                      Structure
                    </h2>
                    {data &&
                      data.children.map((item) => (
                        <NestedItem
                          item={item}
                          setCurrentNavItem={setCurrentNavItem}
                          currentNavItem={currentNavItem ?? null}
                          statusData={statusData}
                          expandedItems={expandedItems}
                          setExpandedItems={setExpandedItems}
                          key={item.id}
                        />
                      ))}
                  </div>
                </section>
              )}
            </div>
          </div>
        )}
      </Container>
    </BasePage>
  );
};

export { StructurePage };
