import { useLocation } from "react-router";
import { Toaster } from "react-hot-toast";
import Dlt360Logo from "../../assets/dlt-logo.svg";
import { useAuth } from "../../contexts/auth_provider";

export default function Header() {
  const location = useLocation();
  const { user, signout } = useAuth();

  function isActiveItem(pathTo: string): boolean {
    return location.pathname === pathTo;
  }
  const selectedNavItemClass =
    "inline-block py-2 px-4 text-white no-underline ";
  const unselectedNavItemClass =
    "flex flex-row items-center justify-start text-gray-600 no-underline hover:text-gray-200 hover:text-underline py-2 px-4 ";

  return (
    <nav className="flex items-center justify-between flex-wrap bg-black p-6 fixed w-full z-10 top-0">
      <Toaster />
      <div className="flex items-center flex-shrink-0 text-white mr-6">
        <a
          className="text-white no-underline hover:text-white hover:no-underline flex flex-row"
          href="/"
        >
          <img className="h-8 w-auto mr-4" src={Dlt360Logo} alt="Workflow" />
          <span className="text-2xl pl-2">
            <i className="em em-grinning"></i> DLT360 Content Manager
          </span>
        </a>
      </div>

      <div className="block lg:hidden">
        <button
          id="nav-toggle"
          onClick={() =>
            document.getElementById("nav-content")!.classList.toggle("hidden")
          }
          className="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600 hover:text-white hover:border-white"
        >
          <svg
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>

      <div
        className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden lg:block pt-6 lg:pt-0"
        id="nav-content"
      >
        <ul className="list-reset lg:flex justify-end flex-1 items-center">
          <li className="mr-3">
            <a
              className={
                isActiveItem("/")
                  ? selectedNavItemClass + "home__a"
                  : unselectedNavItemClass + "home__a"
              }
              href="/"
            >
              Home
            </a>
          </li>

          <li className="mr-3">
            <a
              className={
                isActiveItem("/structure")
                  ? selectedNavItemClass
                  : unselectedNavItemClass
              }
              href="/structure"
            >
              Structure Manager
            </a>
          </li>
          <li className="mr-3">
            <a
              className={
                isActiveItem("/content")
                  ? selectedNavItemClass
                  : unselectedNavItemClass
              }
              href="/content"
            >
              Content Manager
            </a>
          </li>
          {user?.role === "admin" && (
            <li className="mr-3">
              <a
                className={
                  isActiveItem("/chart-types")
                    ? selectedNavItemClass + "type__a"
                    : unselectedNavItemClass + "type__a"
                }
                href="/chart-types"
              >
                Chart Types
              </a>
            </li>
          )}
          {user?.role === "admin" && (
            <li className="mr-3">
              <a
                className={
                  isActiveItem("/users")
                    ? selectedNavItemClass + "user__a"
                    : unselectedNavItemClass + "user__a"
                }
                href="/users"
              >
                User Manager
              </a>
            </li>
          )}
          {user?.role === "admin" && (
            <li className="mr-3">
              <a
                className={
                  isActiveItem("/news")
                    ? selectedNavItemClass + "news__a"
                    : unselectedNavItemClass + "news__a"
                }
                href="/news"
              >
                News Manager
              </a>
            </li>
          )}
          <li className="mr-3">
            <a
              className={
                isActiveItem("/remote_config")
                  ? selectedNavItemClass +
                    " flex flex-row items-center justify-start"
                  : unselectedNavItemClass +
                    " flex flex-row items-center justify-start"
              }
              href="/remote_config"
            >
              Remote Configuration
            </a>
          </li>
          <li className="mr-3" onClick={signout}>
            <a
              className="inline-block text-gray-600 no-underline hover:text-gray-200 hover:text-underline py-2 px-4"
              href="/signin"
            >
              Signout
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}
