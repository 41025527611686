import React from "react";

interface IFrameModalProps {
  link: string;
}

const IFrameModal: React.FunctionComponent<IFrameModalProps> = ({ link }) => {
  return (
    <section className="flex flex-col items-center w-full h-full justify-center pt-5 pr-8 pl-8 ">
      <iframe
        src={link}
        title="IFrame Content"
        className="w-full h-full"
        allowFullScreen
      />
    </section>
  );
};

export { IFrameModal };
