import { useState, useEffect } from "react";
import { InfraColumnsData } from "../../utils/utils";

export function useWebSocketNews() {
  const [columnsData, setColumnsData] = useState<InfraColumnsData>({});
  const [columnLoading, setColumnLoading] = useState(true);

  useEffect(() => {
    const ws = new WebSocket(
      `wss://digital-navigator.herokuapp.com/kanban/feed`
    );

    ws.addEventListener("open", function (event) {});

    ws.addEventListener("message", function (event) {
      try {
        const receivedData: InfraColumnsData = JSON.parse(event.data);

        setColumnsData(receivedData);
      } catch (error) {
        console.error("Error handling message:", error);
      } finally {
        setColumnLoading(false);
      }
    });

    return () => {
      ws.close();
    };
  }, []);

  return { columnsData, columnLoading };
}
