import styled from "styled-components";
import { pinkHover } from "../../../../../styles/app_colors";

export const Container = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #ffffff;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.8em;
  }
  h3 {
    font-size: 1.6em;
  }
  h4 {
    font-size: 1.4em;
  }
  h5 {
    font-size: 1.2em;
  }
  h6 {
    font-size: 1em;
  }
  p {
    font-family: "Inter", sans-serif;
    font-weight: 300;
    font-size: 1em;
    margin-bottom: 8px;
  }
  strong,
  em {
    font-family: "Inter", sans-serif;
    font-size: 1em;
  }
  ul,
  li {
    color: white;
    font-family: "Inter", sans-serif;
    font-weight: 300;
  }
  ol,
  ul {
    margin-bottom: 16px;
    margin-left: 20px;
  }

  blockquote {
    background-color: modalBackground;
    border-left: 3px solid #ccc;
    padding: 0.5em 10px;
  }

  blockquote p {
    display: inline;
    font-family: "Inter", sans-serif;
    font-weight: 300;
    font-size: 1em;
  }

  p code {
    background-color: #b85e82;
    border: 0.2px solid #a0325e;
    border-radius: 8px;
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    color: blue;
    font-family: "Inter", sans-serif;
    font-weight: 300;
    font-size: 1em;
    code {
      font-family: "Inter", sans-serif;
      font-weight: 300;
      font-size: 1em;
    }
  }

  img {
    width: 100%;
  }
  a {
    color: ${pinkHover};
    font-family: "Inter", sans-serif;
    font-weight: 300;
  }
`;
export const ContentSection = styled.div`
  margin-bottom: 24px;
  h2 {
    color: white;
    font-weight: 700;
    font-family: "Inter";
    margin-bottom: 8px;
  }

  p {
    font-size: 1em;
    font-weight: 300;
    font-family: "Inter";
  }
`;

export const LinkRow = styled.div`
  display: flex;
  margin-bottom: 8px;
  width: 100%;
  gap: 5px;
  p {
    font-size: 1em;
    font-weight: 300;
    font-family: "Inter";
  }

  a {
    font-family: "Inter";
    font-size: 1em;
    font-weight: 300;

    color: white;
  }
`;
