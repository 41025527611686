export interface NavigatorContent {
  id: string;
  title: string;
  section_id: string;
  content: string;
  to_approve: string;
  footer: string;
  permissions: Array<string>;
  status: number;
  created_at: Date;
  updated_at: Date;
  related_sections?: Array<Array<ContentRelactedSectionValues>>;
}

export interface ContentRelatedSection {
  data: Array<ContentRelactedSectionValues>;
}

export interface ContentRelactedSectionValues {
  Key: string;
  Value: string;
}

export type ContentStatusDictionary = {
  [key: string]: number;
};

export const ContentStatusDict: ContentStatusDictionary = {
  "In Progress": 0,
  "Ready for approval": 1,
  Approved: 2,
};

export interface ChangeStatusInterface {
  contentId: string
  body: {
    status: number
  }
}

export interface CreateMultipleContentRequest {
  title: string
  section_id: string
  content: string | undefined
  permissions: string[]
  section_ids: string[]
  related_sections: ContentRelatedSection[]
}