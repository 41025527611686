import { NavigatorChart, NavItem, SelectibleNavItem } from "../shared/types";

function findNodeByName(chart: NavigatorChart, name: string): NavItem[] {
  let found: NavItem[] = [];
  const traverse = (chart: NavigatorChart, name: string): void => {
    const children = chart.children ?? [];
    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      if (child.name === name) {
        found.push(child);
      }
      if (child.children) {
        traverse({ name: "", id: "", children: child.children }, name);
      }
    }
  };

  traverse(chart, name);
  return found;
}
function setSelectionNodeById(
  chart: NavigatorChart,
  id: string,
  isSelected: boolean
): NavigatorChart {
  const traverse = (chart: NavigatorChart, id: string): boolean => {
    const children = chart.children ?? [];
    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      if (child.id === id) {
        (child as SelectibleNavItem).selected = isSelected;
        return true;
      }
      if (child.children) {
        if (traverse({ name: "", id: "", children: child.children }, id)) {
          return true;
        }
      }
    }
    return false;
  };

  traverse(chart, id);
  return chart;
}
function findSelectedItems(chart: NavigatorChart): SelectibleNavItem[] {
  const selectedItems: SelectibleNavItem[] = [];

  const traverse = (items: NavItem[]): void => {
    items.forEach((item) => {
      const selectableItem = item as SelectibleNavItem;
      if (selectableItem.selected) {
        selectedItems.push(selectableItem);
      }
      if (item.children) {
        traverse(item.children);
      }
    });
  };

  traverse(chart.children);
  return selectedItems;
}

// RECURSIVE
function processNodesContentCount(nodes: NavItem[]): Record<string, any> {
  const result: Record<string, any> = {};

  nodes.forEach((node) => {
    const { children, content_id, name } = node;

    // Initialize the result object for the current child
    result[name] = {
      name: node.name,
      children_content_ids: [],
      children_without_content_count: 0,
    };

    // If there are nested children, process them recursively
    if (children && children.length > 0) {
      const childResult = processNodesContentCount(children);

      // Combine the results from the nested children
      Object.values(childResult).forEach((childData: any) => {
        result[name].children_content_ids.push(
          ...childData.children_content_ids
        );
        result[name].children_without_content_count +=
          childData.children_without_content_count;
      });
    }

    // Process the current node's content_id if it's a leaf node (no children)
    if (!children || children.length === 0) {
      if (content_id) {
        result[name].children_content_ids.push(content_id);
      } else {
        result[name].children_without_content_count += 1;
      }
    }
  });

  return result;
}

function processStatus(
  nodes: NavItem[],
  chartStatus: Record<string, any>[]
): Record<string, any> {
  const result: Record<string, any> = {};

  const getStatusCount = (
    content_id: string | undefined,
    statusCounts: Record<string, number>
  ) => {
    const statusData = content_id
      ? chartStatus.find((item) => item.id === content_id)
      : undefined;

    if (statusData) {
      type StatusKey = keyof typeof statusData;
      const statusKeyMap: Record<StatusKey, string> = {
        0: "In Progress",
        1: "Awaiting Approval",
        2: "Approved",
      };
      const statusKey = statusKeyMap[statusData.status as StatusKey];
      statusCounts[statusKey] += 1;
    } else {
      statusCounts["No Content"] += 1;
    }
  };

  const processNodeStatus = (node: NavItem): Record<string, number> => {
    let statusCounts: Record<string, number> = {
      "In Progress": 0,
      "Awaiting Approval": 0,
      Approved: 0,
      "No Content": 0,
    };

    getStatusCount(node.content_id, statusCounts);

    if (node.children && node.children.length > 0) {
      node.children.forEach((child) => {
        const childStatusCounts = processNodeStatus(child);

        // Combine status counts
        Object.keys(childStatusCounts).forEach((key) => {
          statusCounts[key] = (statusCounts[key] || 0) + childStatusCounts[key];
        });
      });
    }

    return statusCounts;
  };

  nodes.forEach((node) => {
    const { name } = node;
    result[name] = processNodeStatus(node);
  });

  return result;
}

function countNodes(nodes: NavItem[]): any[] {
  const result: any[] = [];

  const processNodeCount = (node: NavItem): number => {
    let count = 1;

    if (node.children && node.children.length > 0) {
      node.children.forEach((child) => {
        count += processNodeCount(child);
      });
    }

    return count;
  };

  nodes.forEach((node) => {
    const { name } = node;
    result.push({ name, value: processNodeCount(node) });
  });

  return result;
}
export {
  findNodeByName,
  setSelectionNodeById,
  findSelectedItems,
  processNodesContentCount,
  processStatus,
  countNodes,
};
