import CryptoJS from "crypto-js";

const secretKey = process.env.REACT_APP_ENCRYPT_KEY || "A";

function encryptString(plainText: string) {
  const encrypted = CryptoJS.AES.encrypt(plainText, secretKey).toString();
  return encrypted;
}

function decryptString(encryptedText: string) {
  const decrypted = CryptoJS.AES.decrypt(encryptedText, secretKey).toString(
    CryptoJS.enc.Utf8
  );
  return decrypted;
}

export { encryptString, decryptString };
