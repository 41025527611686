import { HEROKU_API_BASE_URL } from "../utils/api_endpoints";
import { decryptString, encryptString } from "../utils/crypto";
import { User } from "../utils/types/user";
import axios from "axios";
import Cookie from "js-cookie";

export const setAuthTokens = (
  accessToken: string,
  refreshToken: string,
  user: User
) => {
  const userStr = JSON.stringify(user);
  const encryptedUser = encryptString(userStr);
  Cookie.set("access_token", accessToken, { expires: 1 / 12 }); // Expires in 2 hours
  Cookie.set("refresh_token", refreshToken, { expires: 30 }); // Expires in 30 days
  Cookie.set("user", encryptedUser, { expires: 30 });
};

export const removeAuthTokens = () => {
  Cookie.remove("access_token");
  Cookie.remove("refresh_token");
  Cookie.remove("user");
};

export const getAccessToken = () => {
  const access_token = Cookie.get("access_token");
  if (!access_token) return null;
  return access_token;
};

export const getRefreshToken = () => {
  const refresh_token = Cookie.get("refresh_token");
  if (!refresh_token) return null;
  return refresh_token;
};

export const getUser = () => {
  const encryptedUser = Cookie.get("user");
  if (!encryptedUser) return null;
  const userStr = decryptString(encryptedUser!);
  const user: User = JSON.parse(userStr);
  return user;
};

export const loginUser = async (emailSignin: string, password: string) => {
  try {
    const response = await axios.post(`${HEROKU_API_BASE_URL}/users/signin`, {
      email: emailSignin,
      password,
    });

    const { token, refresh_token, email, first_name, last_name, id, role } =
      response.data;
    setAuthTokens(token, refresh_token, {
      email,
      first_name,
      last_name,
      id,
      role,
    });

    return { success: true, user: { email, first_name, last_name, id, role } };
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response && error.response.data) {
      return { success: false, error: error.response.data.error };
    } else {
      return { success: false, error: "An error occurred during login" };
    }
  }
};

export const signoutUser = async () => {
  removeAuthTokens();
};
