import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { NavigatorContent } from "../../../../../shared/types";
import * as S from "./styles";

const ModalContent: React.FunctionComponent<{
  content: NavigatorContent;
  title: string;
}> = ({ content, title }) => {
  return (
    <S.Container className="content">
      <ReactMarkdown
        components={{ a: (props) => LinkRenderer({ title, ...props }) }}
        remarkPlugins={[remarkGfm]}
        children={
          content.content !== ""
            ? content.content
            : "Coming soon - No content in category. Sorry. We could not find any information for this section. You can proceed to the next one or go back."
        }
      />
    </S.Container>
  );
};

function LinkRenderer(props: any) {
  return (
    <a href={props.href} target="_blank" rel="noreferrer" onClick={() => null}>
      {props.children}
    </a>
  );
}

export { ModalContent };
