import React, { useEffect } from "react";
import { toast } from "react-hot-toast";
import ReactLoading from "react-loading";
import {
  useGetNewsTags,
  useCreateNewsTag,
  useUpdateNewsTag,
} from "../../../infra/api";
import { NewsTag } from "../../../utils/types/news-radar";
import { CustomButton } from "../../styles/custom_button";

const ViewNewsTagsModal: React.FunctionComponent = () => {
  const [currentMode, setCurrentMode] = React.useState<"none" | "creating">(
    "none"
  );
  const [newTagTitle, setNewTagTitle] = React.useState<string>("");

  const {
    isLoading: tagsIsLoading,
    data,
    isRefetching: tagsIsRefetching,
  } = useGetNewsTags();
  const { mutateAsync: createTag, isLoading: creationIsLoading } =
    useCreateNewsTag();

  const isLoading = tagsIsLoading || tagsIsRefetching || creationIsLoading;

  const sendCreateTag = React.useCallback(
    async (title: string) => {
      createTag({ title: title }).then(() => {
        toast.success("Created child with success", {
          duration: 1000,
        });
      });
    },
    [createTag]
  );
  useEffect(() => {}, []);

  return isLoading ? (
    <section
      style={{ width: "80vw", height: "90%" }}
      className=" flex items-center justify-center"
    >
      <ReactLoading type="cylon" color="#000000" height={"10%"} width={"10%"} />
    </section>
  ) : (
    <section
      style={{ width: "80vw", height: "90%", overflowY: "auto" }}
      className=" flex items-start justify-start"
    >
      <div className="flex flex-col items-start justify-center p-5 w-full align-content-start">
        <div className="flex items-start gap-6 pt-1 flex-wrap">
          <h1 className="text-2xl font-semibold antialiased uppercase">
            News Tags
          </h1>
          <div className="flex items-start gap-6">
            <CustomButton
              label={currentMode === "creating" ? "View Tags" : "Create Tag"}
              onClick={() => {
                currentMode === "creating"
                  ? setCurrentMode("none")
                  : setCurrentMode("creating");
              }}
            />
          </div>
        </div>
        {currentMode === "none" && (
          <div className="flex flex-wrap w-full  mt-10">
            {data?.map((t) => (
              <Tag {...t} />
            ))}
          </div>
        )}
        {currentMode === "creating" && (
          <>
            <label className="text-lg font-semibold antialiased mt-10 ">
              Tag Title
            </label>
            <input
              type="text"
              className="border rounded-md py-2 px-3 mb-6 w-300"
              value={newTagTitle}
              placeholder="Digital Innovation"
              onChange={(e) => setNewTagTitle(e.target.value)}
            />

            <div className="flex items-center justify-center gap-x-6">
              <CustomButton
                tailwindColor="bg-blue-600"
                label={`Create Tag`}
                onClick={() => {
                  sendCreateTag(newTagTitle);
                  setCurrentMode("none");
                }}
                disabled={newTagTitle === ""}
              />
            </div>
          </>
        )}
      </div>
    </section>
  );
};

const Tag = (tag: NewsTag) => {
  const [isClicked, setIsClicked] = React.useState<boolean>(false);
  const [titleEdition, setTitleEdition] = React.useState<string>(tag.title);
  const { mutateAsync: editTag, isError: editionError } = useUpdateNewsTag();

  const sendUpdate = React.useCallback(
    async (tag: NewsTag) => {
      editTag(tag).then(() => {
        toast.success("Created child with success", {
          duration: 1000,
        });
      });
    },
    [editTag]
  );
  return (
    <div
      key={tag.id}
      onClick={() => {
        if (!isClicked) {
          setIsClicked(true);
        }
      }}
      className="h-min flex items-center justify-center  m-4 px-4 py-2 rounded-md hover:bg-gray-500 cursor-pointer bg-black"
    >
      {!isClicked && <span className="text-white">{tag.title}</span>}
      {isClicked && (
        <div className="flex flex-col items-start justify-center">
          <label className="text-lg font-semibold antialiased mt-10 text-white ">
            Edit Tag
          </label>
          <input
            type="text"
            className="border rounded-md py-2 px-3 mb-6 w-300"
            value={titleEdition}
            onChange={(e) => setTitleEdition(e.target.value)}
          />
          <CustomButton
            tailwindColor="bg-blue-600"
            label="Save"
            onClick={() => {
              sendUpdate({ ...tag, title: titleEdition });
            }}
            disabled={titleEdition === "" || titleEdition === tag.title}
          />
          <CustomButton
            tailwindColor="bg-red-600 mt-2"
            label="Close"
            onClick={() => {
              setIsClicked((state) => !state);
            }}
          />

          {editionError && (
            <span className="font-semibold text-xl text-red-500 mt-4">
              Some error ocurred
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export { ViewNewsTagsModal };
