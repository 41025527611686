import React, { useState } from "react";
import { FaRegCaretSquareDown, FaRegCaretSquareRight } from "react-icons/fa";
import { SelectibleNavItem } from "../../../shared/types";

interface NavItemSelectibleProps {
  item: SelectibleNavItem;
  setCurrentNavItem: React.Dispatch<
    React.SetStateAction<SelectibleNavItem | null>
  >;
  currentNavItem: SelectibleNavItem | null;
  onSelect: (item: SelectibleNavItem, isSelected: boolean) => void;
}
const NestedSelectibleItem: React.FunctionComponent<NavItemSelectibleProps> = ({
  item,
  setCurrentNavItem,
  currentNavItem,
  onSelect,
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  const isEqual = currentNavItem?.id === item.id;
  const itemStyle = {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  };
  const textColor = (): string => {
    if (item.content_id) {
      return "text-gray-400";
    }
    if (isEqual) {
      return "text-pink-600";
    } else {
      return "text-black";
    }
  };
  return (
    <div style={{ marginLeft: 20 }} key={item.id}>
      <div
        className="w-96 flex items-center  justify-start  hover:text-gray-600 hover:border-red hover:bg-pink-100"
        style={itemStyle}
        title={item.content_id ? "This section already owns a content" : ""}
        onClick={() => {
          setCurrentNavItem(item);
          return toggleExpand();
        }}
      >
        {item.children && (
          <>{expanded ? <FaRegCaretSquareDown /> : <FaRegCaretSquareRight />}</>
        )}
        <p
          className={textColor()}
          style={{ marginLeft: "4px", fontSize: "1.2em" }}
        >
          {item.name}
        </p>
        {!item.content_id && (
          <input
            type="checkbox"
            className="form-checkbox h-4 w-4 accent-pink-600 ml-2 z-50"
            checked={item.selected === true}
            onChange={(e) => {
              onSelect(item, e.target.checked);
            }}
          />
        )}
      </div>
      {expanded && item.children && (
        <div>
          {item.children.map((child, index) => (
            <NestedSelectibleItem
              onSelect={onSelect}
              item={child}
              setCurrentNavItem={setCurrentNavItem}
              currentNavItem={currentNavItem}
              key={item.id + index}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export { NestedSelectibleItem };
