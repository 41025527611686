import React, { useRef, useEffect } from "react";
import { toast } from "react-hot-toast";
import ReactLoading from "react-loading";
import { useCreateBaseChartNode } from "../../../infra/api";
import { NavigatorChart, NavItem } from "../../shared/types";
import { CustomButton } from "../../styles/custom_button";

interface ViewSectionModalProps {
  onClose: () => void;
  onChangeStructure: () => void;
  navigatorStructure?: NavigatorChart;
}

const CreateBaseNodeModal: React.FunctionComponent<ViewSectionModalProps> = ({
  onChangeStructure,
  navigatorStructure,
}) => {
  const emptyNavItem: NavItem = {
    category: "",
    feedback_link: "",
    is_visible: true,
    name: "",
    minimum_permission_level: "",
    id: "",
    presentation_link: "",
    value: 3,
  };
  const contentRef = useRef<HTMLDivElement>(null);

  const [creatingNavItem, setcreatingNavItem] =
    React.useState<NavItem>(emptyNavItem);

  const {
    mutateAsync: createNode,
    isLoading: createIsLoading,
    isError: createIsError,
  } = useCreateBaseChartNode();

  const isError = createIsError;
  const isLoading = createIsLoading;

  useEffect(() => {
    if (contentRef.current) {
      const contentHeight = contentRef.current.scrollHeight;
      const modalHeight = window.innerHeight * 0.8;
      if (contentHeight > modalHeight) {
        contentRef.current.classList.add("overflow-y-scroll");
      } else {
        contentRef.current.classList.remove("overflow-y-scroll");
      }
    }
  }, []);

  const sendCreateChild = React.useCallback(async () => {
    const data = {
      name: creatingNavItem.name,
      is_visible: creatingNavItem.is_visible,
      minimum_permission_level: "level_1",
      category: "cat",
      presentation_link:
        creatingNavItem.presentation_link.length > 0
          ? creatingNavItem.presentation_link
          : "",
      feedback_link:
        creatingNavItem.feedback_link.length > 0
          ? creatingNavItem.feedback_link
          : "",
    };
    createNode(data).then(() => {
      if (isError) {
        toast.error("Error Creating. Something happened.");
      } else {
        toast.success("Created child with success", {
          duration: 1000,
        });

        onChangeStructure();
      }
    });
  }, [
    creatingNavItem.name,
    creatingNavItem.is_visible,
    creatingNavItem.presentation_link,
    creatingNavItem.feedback_link,
    createNode,
    isError,
    onChangeStructure,
  ]);

  return isLoading ? (
    <section
      style={{ width: "80vw", height: "90%" }}
      className=" flex items-center justify-center"
    >
      <ReactLoading type="cylon" color="#000000" height={"10%"} width={"10%"} />
    </section>
  ) : (
    <section
      style={{ width: "80vw", height: "90%", overflowY: "auto" }}
      className=" flex items-start justify-start"
    >
      <div className="flex flex-col items-start justify-center p-5 w-full align-content-start">
        <div className="flex items-start gap-6 pt-1 flex-wrap">
          <div className="flex items-start gap-6"></div>
        </div>

        <div className="w-full bg-black mt-10 pl-2">
          <label className="text-xl text-white font-semibold antialiased mt-10 ">
            Creating Base Node
          </label>
        </div>

        <>
          <label className="text-lg font-semibold antialiased mt-10 ">
            Name
          </label>
          <input
            type="text"
            className="border rounded-md py-2 px-3 mb-6 w-300"
            value={creatingNavItem.name}
            onChange={(e) =>
              setcreatingNavItem({ ...creatingNavItem, name: e.target.value })
            }
          />
          <div className="flex items-center justify-center gap-x-4">
            <label className="text-lg font-semibold antialiased">
              Is Visible
            </label>
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-green-600  "
              checked={creatingNavItem.is_visible}
              onChange={(e) =>
                setcreatingNavItem({
                  ...creatingNavItem,
                  is_visible: e.target.checked,
                })
              }
            />
          </div>
          <label className="text-lg font-semibold antialiased mt-6">
            Presentation Link
          </label>
          <input
            type="text"
            className="border rounded-md py-2 px-3 mb-6"
            value={creatingNavItem.presentation_link}
            onChange={(e) =>
              setcreatingNavItem({
                ...creatingNavItem,
                presentation_link: e.target.value,
              })
            }
          />
          <label className="text-lg font-semibold antialiased">
            Feedback Link
          </label>
          <input
            type="text"
            className="border rounded-md py-2 px-3 mb-10"
            value={creatingNavItem.feedback_link}
            onChange={(e) =>
              setcreatingNavItem({
                ...creatingNavItem,
                feedback_link: e.target.value,
              })
            }
          />
          <div className="flex items-center justify-center gap-x-6">
            <CustomButton
              tailwindColor="bg-blue-600"
              label={`Create child`}
              onClick={() => sendCreateChild()}
            />
            <CustomButton
              tailwindColor="bg-red-500"
              label="Discart changes"
              onClick={() => {
                setcreatingNavItem(emptyNavItem);
              }}
            />
          </div>
        </>
      </div>
    </section>
  );
};

export { CreateBaseNodeModal };
