import React, { FC, MouseEvent } from "react";
import { FaRegTimesCircle } from "react-icons/fa";

interface ModalProps {
  show: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export const Modal: FC<ModalProps> = ({ show, onClose, children }) => {
  if (!show) {
    return null;
  }

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-lg p-4 md:pr-8 relative"
        style={{ height: "90%" }}
        onClick={handleClick}
      >
        <button
          className="absolute top-1 right-1 text-gray-500 hover:text-gray-900 font-bold text-2xl cursor-pointer"
          onClick={onClose}
        >
          <FaRegTimesCircle size={30} className="text-pink-500" />
        </button>
        {children}
      </div>
    </div>
  );
};
