import React from "react";
import { Container, FirstStateContainer } from "./style";
import { ReactComponent as NavigatorMainCircle } from "./assets/navigator-main.svg";
import { LimitedNavigatorChart, NavigatorChart } from "../../shared/types";
import { useGetChart } from "../../../infra/api";
import {
  recursivelyIterateThroughChart,
  recursivelyIterateThroughChartToFindParent,
} from "./functions";
import { Sunburst } from "./chart";

interface SunburstPreviewPRops {
  version: string;
}
const SunburstPreview: React.FunctionComponent<SunburstPreviewPRops> = ({
  version,
}) => {
  const [chartData, setChartData] = React.useState<any>(null);
  const [hasClickedOnce, setHasClickedOnce] = React.useState(false);
  const [apiChartData, setApiChartData] =
    React.useState<LimitedNavigatorChart>();
  const [isFirstAccess, setIsFirstAccess] = React.useState(true);
  const { data } = useGetChart(version);

  const removeSectionFromURL = React.useCallback(() => {
    let currentHref = window.location.href;
    let newURL = currentHref.substring(0, currentHref.lastIndexOf("/"));
    window.history.pushState(null, "", newURL);
  }, []);

  function limitDataToTwoLevels(
    data: Partial<NavigatorChart>
  ): Partial<LimitedNavigatorChart> {
    var limitedChart: Partial<LimitedNavigatorChart> = { ...data };
    if (limitedChart.children) {
      const depth2Children = limitedChart.children;
      limitedChart.children = depth2Children.map((depth2Child: any) => {
        if (depth2Child.children) {
          const depth3Children = depth2Child.children.map((item: any) => {
            if (item.children) {
              return { ...item, children: null, hasChildren: true };
            }
            return { ...item, hasChildren: false };
          });
          return {
            ...depth2Child,
            children: depth3Children,
            hasChildren: true,
          };
        }
        return { ...depth2Child, hasChildren: false };
      });
      limitedChart.hasChildren = true;
    } else {
      limitedChart.hasChildren = false;
    }
    return limitedChart;
  }

  const getCodesInURL = React.useCallback((): string[] => {
    const codes = window.location.href.split("/");
    const codesInURL = codes.filter(
      (code) =>
        code.length > 6 &&
        !code.includes("digital-navigato") &&
        !code.includes("localhost")
    );
    return codesInURL;
  }, []);

  const setChartDataBasedOnURL = React.useCallback(() => {
    const codesInURL = getCodesInURL();
    if (codesInURL.length > 0) {
      const lastCode = codesInURL[codesInURL.length - 1].replace(/%20/g, " ");
      if (lastCode && apiChartData !== undefined) {
        const newData = recursivelyIterateThroughChart(lastCode, apiChartData);
        if (newData) setChartData(limitDataToTwoLevels({ ...newData }));
      }
    }
    setIsFirstAccess(false);
  }, [apiChartData, getCodesInURL]);

  const setsUrlOnLoad = React.useCallback(() => {
    const href = window.location.href
      .replace(/%5B%5B/g, "[[")
      .replace(/%5D%5D/g, "]]");
    window.history.pushState(null, "", href);
  }, []);

  React.useEffect(() => {
    if (data !== undefined) {
      setApiChartData(data);
      setChartData(limitDataToTwoLevels(data) as LimitedNavigatorChart);
    }
    setsUrlOnLoad();
  }, [data, setsUrlOnLoad]);

  React.useEffect(() => {
    if (isFirstAccess && apiChartData) {
      setChartDataBasedOnURL();
      if (window.location.pathname !== "/") {
        setHasClickedOnce(true);
      }
    }
  }, [isFirstAccess, setChartDataBasedOnURL, apiChartData]);

  const updateChartDataOnClickForward = React.useCallback(
    async (sectionId: string) => {
      if (apiChartData !== undefined) {
        const newData = recursivelyIterateThroughChart(sectionId, apiChartData);
        setChartData(limitDataToTwoLevels({ ...newData }));
      }
    },
    [apiChartData]
  );

  const updateChartDataOnClickBack = React.useCallback(
    async (sectionFullString: string) => {
      if (apiChartData !== undefined) {
        const newData = recursivelyIterateThroughChartToFindParent(
          sectionFullString,
          apiChartData
        );
        setChartData(limitDataToTwoLevels({ ...newData }));
      }
    },
    [apiChartData]
  );

  return (
    <Container className="dv-home">
      {!hasClickedOnce && (
        <FirstStateContainer>
          <NavigatorMainCircle onClick={() => setHasClickedOnce(true)} />
        </FirstStateContainer>
      )}
      {chartData && hasClickedOnce && (
        <section className="chart-container">
          <Sunburst
            data={chartData}
            keyId="chart"
            width={600}
            onNextSectionClickCallback={(sectionFullString: string) =>
              updateChartDataOnClickForward(sectionFullString)
            }
            onCenterClickCallback={(sectionFullString: string) =>
              updateChartDataOnClickBack(sectionFullString)
            }
            removeSectionFromURL={(code: string) => removeSectionFromURL()}
          />
        </section>
      )}
    </Container>
  );
};

export { SunburstPreview };
