import { Draggable } from "react-beautiful-dnd";
import { toast } from "react-hot-toast";
import { FaLink, FaPaperPlane } from "react-icons/fa";
import { NewsItem } from "../../../utils/types/news-radar";
import { formatDateTimeWithoutHour, TKanbanCard } from "../../../utils/utils";
import "./kanban_styles.css";

type CardProps = {
  card: TKanbanCard;
  index: number;
  onCardClick: (card: TKanbanCard) => void;
  onSendClick: (news: NewsItem, card: TKanbanCard, from_column: string) => void;
  column_id: string;
};

const KanbanCard = ({
  card,
  index,
  onCardClick,
  column_id,
  onSendClick,
}: CardProps) => {
  if (index > 15) return <></>;

  return (
    <Draggable draggableId={card.id} index={index}>
      {(provided, snapshot) => (
        <div
          className={`bg-black w-36 card ${
            snapshot.isDragging ? "dragging" : ""
          }`}
          onClick={() => onCardClick(card)}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div className="flex items-center justify-between gap-2">
            <span className="text-lg font-semibold antialiased uppercase line-clamp-2">
              {card.title}
            </span>
          </div>
          <span className="text-md text-gray-700 antialiased line-clamp-3">
            {card.description}
          </span>

          <div className="flex items-center justify-between mt-2 ">
            <div className="flex items-center justify-start">
              {card.link && (
                <a
                  href={card.link}
                  className="flex items-center justify-start text-blue-700 z-10"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLink />
                </a>
              )}
              {card.notice_date && (
                <span className="ml-2 text-sm italic text-gray-500 antialiased">
                  {formatDateTimeWithoutHour(card.notice_date)}
                </span>
              )}
              {card.publishedAt && (
                <span className="ml-2 text-sm italic text-gray-500 antialiased">
                  {formatDateTimeWithoutHour(card.publishedAt)}
                </span>
              )}
            </div>
            {column_id === "column-4" && (
              <div
                className="p-2 flex  hover:bg-black  hover:text-white z-20"
                onClick={() => {
                  console.log(card.content);
                  const nt: NewsItem = {
                    content: card.content,
                    description: card.description,
                    title: card.title,
                    id: card.id,
                    notice_date: card.notice_date ?? "",
                    news_categories_ids: [],
                    is_important: false,
                    tags_ids: [],
                    related_nav_sections: [],
                    links: [],
                    created_at: "",
                    updated_at: "",
                    upvotes_count: 0,
                    downvotes_count: 0,
                  };
                  toast.success("click");
                  onSendClick(nt, card, column_id.charAt(column_id.length - 1));
                }}
              >
                <FaPaperPlane className="text-blue-500" />
              </div>
            )}
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default KanbanCard;
