import React from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { KanbanBoardProps } from "../../../utils/utils";
import KanbanColumn from "./kanban_column";

const KanbanBoard = ({
  data,
  onDragEnd,
  onCardClick,
  onSendClick,
}: KanbanBoardProps) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {data.columnOrder.map((columnId) => {
        const column = data.columns[columnId];
        const cards = column.cardIds.map((cardId) => data.cards[cardId]); // changed dt to cardId
        return (
          <KanbanColumn
            key={column.id}
            column={column}
            cards={cards}
            onCardClick={onCardClick}
            onSendClick={onSendClick}
          />
        );
      })}
    </DragDropContext>
  );
};

export default KanbanBoard;
