import axios from "axios";
import React from "react";
import { toast } from "react-hot-toast";
import ReactLoading from "react-loading";
import { RemoteConfig } from "../../shared/types";
import { CustomButton } from "../../styles/custom_button";
import { API_BASE_URL } from "../../utilts/constants/constants";
import { BasePage } from "../base";

import { Container } from "./style";

const RemoteConfigPage: React.FunctionComponent = () => {
  const [data, setData] = React.useState<RemoteConfig | null>(null);
  const [editData, setEditData] = React.useState<RemoteConfig | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const getRemoteConfig = React.useCallback(async () => {
    setIsLoading(true);
    setData(null);
    setEditData(null);
    const { data } = await axios.get(`${API_BASE_URL}/v2/remote_config`);
    setData(data);
    setEditData(data);
    setIsLoading(false);
  }, []);

  const updateRemoteConfig = React.useCallback(async () => {
    setIsLoading(true);

    const response = await axios.put(
      `${API_BASE_URL}/v2/remote_config`,
      editData
    );

    if (response.status === 200) {
      getRemoteConfig();
      toast("Remote Config updated successfully", {
        icon: "👍",
        duration: 3000,
      });
    } else {
      toast("Something went wrong", {
        icon: "😥",
        duration: 3000,
      });
    }

    setIsLoading(false);
  }, [getRemoteConfig, editData]);

  React.useEffect(() => {
    getRemoteConfig();
  }, [getRemoteConfig]);

  return (
    <BasePage>
      <Container className="dv__remote-config">
        <h1 className="text-2xl font-bold antialising mb-8">
          Remote Configuration{" "}
        </h1>

        {isLoading ? (
          <section
            style={{ width: "80vw", height: "90vh" }}
            className=" flex items-center justify-center"
          >
            <ReactLoading
              type="cylon"
              color="#000000"
              height={"10%"}
              width={"10%"}
            />
          </section>
        ) : (
          <section className="flex flex-col gap-6 ">
            <div className="flex flex-col">
              <label
                htmlFor="content_feedback_url"
                className="font-semibold antialising"
              >
                Content Feedback URL
              </label>
              <input
                id="content_feedback_url"
                type="text"
                className="p-2 border border-gray-300 rounded"
                value={editData?.content_feedback_url}
                onChange={(e) =>
                  setEditData({
                    ...editData!,
                    content_feedback_url: e.target.value,
                  })
                }
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="content_presentation_url"
                className="font-semibold antialising"
              >
                Content Presentation URL
              </label>
              <input
                id="content_presentation_url"
                type="text"
                className="p-2 border border-gray-300 rounded"
                value={editData?.content_presentation_url}
                onChange={(e) =>
                  setEditData({
                    ...editData!,
                    content_presentation_url: e.target.value,
                  })
                }
              />
            </div>

            <div className="flex flex-col">
              <label
                htmlFor="content_radar_url"
                className="font-semibold antialising"
              >
                Content Radar URL
              </label>
              <input
                id="content_radar_url"
                type="text"
                className="p-2 border border-gray-300 rounded"
                value={editData?.content_radar_url}
                onChange={(e) =>
                  setEditData({
                    ...editData!,
                    content_radar_url: e.target.value,
                  })
                }
              />
            </div>

            <div className="flex flex-col">
              <label
                htmlFor="content_radar_url"
                className="font-semibold antialising"
              >
                Website Contact
              </label>
              <input
                id="website_contact"
                type="text"
                className="p-2 border border-gray-300 rounded"
                value={editData?.website_contact}
                onChange={(e) =>
                  setEditData({
                    ...editData!,
                    website_contact: e.target.value,
                  })
                }
              />
            </div>

            <div className="flex flex-col">
              <label
                htmlFor="content_radar_url"
                className="font-semibold antialising"
              >
                Website Footer Text
              </label>
              <input
                id="website_footer_text"
                type="text"
                className="p-2 border border-gray-300 rounded"
                value={editData?.website_footer_text}
                onChange={(e) =>
                  setEditData({
                    ...editData!,
                    website_footer_text: e.target.value,
                  })
                }
              />
            </div>

            <div className="flex flex-col">
              <label
                htmlFor="action_bar_help_button_url"
                className="font-semibold antialising"
              >
                ActionBar Help Button URL
              </label>
              <input
                id="action_bar_help_button_url"
                type="text"
                className="p-2 border border-gray-300 rounded"
                value={editData?.action_bar_help_button_url}
                onChange={(e) =>
                  setEditData({
                    ...editData!,
                    action_bar_help_button_url: e.target.value,
                  })
                }
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="action_bar_back_button_url"
                className="font-semibold antialising"
              >
                ActionBar Back Button URL
              </label>
              <input
                id="action_bar_back_button_url"
                type="text"
                className="p-2 border border-gray-300 rounded"
                value={editData?.action_bar_back_button_url}
                onChange={(e) =>
                  setEditData({
                    ...editData!,
                    action_bar_back_button_url: e.target.value,
                  })
                }
              />
            </div>

            <div className="flex flex-row items-center justify-center gap-6 mt-10">
              <CustomButton
                label="Save Changes"
                disabled={data === editData}
                onClick={updateRemoteConfig}
              />
              <CustomButton
                label="Discart Changes"
                onClick={() => setEditData(data)}
              />
            </div>
          </section>
        )}
      </Container>
    </BasePage>
  );
};

export { RemoteConfigPage };
