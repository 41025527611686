import React from "react";
import { toast } from "react-hot-toast";
import ReactLoading from "react-loading";
import InputColor from "react-input-color";
import {
  useGetNewsCategories,
  useCreateNewsCategory,
  useUpdateNewsCategory,
} from "../../../infra/api";
import { NewsCategory } from "../../../utils/types/news-radar";
import { CustomButton } from "../../styles/custom_button";

const ViewNewsCategoriesModal: React.FunctionComponent = () => {
  const [currentMode, setCurrentMode] = React.useState<
    "none" | "creating" | "editing"
  >("none");
  const [creatingCategory, setCreatingCategory] = React.useState<
    Partial<NewsCategory>
  >({ acronym: "", color_hex: "", name: "" });
  const [editingCategory, setEditingCategory] = React.useState<
    Partial<NewsCategory> | undefined
  >(undefined);
  const {
    isLoading: categoriesIsLoading,
    data,
    isRefetching: categoriesIsRefetching,
  } = useGetNewsCategories();
  const { mutateAsync: createCategory, isLoading: creationIsLoading } =
    useCreateNewsCategory();
  const { mutateAsync: updateCategory, isLoading: updateIsLoading } =
    useUpdateNewsCategory();

  const isLoading =
    categoriesIsLoading ||
    categoriesIsRefetching ||
    creationIsLoading ||
    updateIsLoading;

  const sendCreateCategory = React.useCallback(
    async (category: Partial<NewsCategory>) => {
      createCategory(category).then(() => {
        toast.success("Created category with success", {
          duration: 1000,
        });
      });
    },
    [createCategory]
  );

  const sendUpdateCategory = React.useCallback(
    async (category: Partial<NewsCategory>) => {
      updateCategory(category).then(() => {
        toast.success("Created category with success", {
          duration: 1000,
        });
      });
    },
    [updateCategory]
  );

  return isLoading ? (
    <section
      style={{ width: "80vw", height: "90%" }}
      className=" flex items-center justify-center"
    >
      <ReactLoading type="cylon" color="#000000" height={"10%"} width={"10%"} />
    </section>
  ) : (
    <section
      style={{ width: "80vw", height: "90%", overflowY: "auto" }}
      className=" flex items-start justify-start"
    >
      <div className="flex flex-col items-start justify-center p-5 w-full align-content-start">
        <div className="flex items-start gap-6 pt-1 flex-wrap">
          <h1 className="text-2xl font-semibold antialiased uppercase">
            News Categories
          </h1>
          <div className="flex items-start gap-6">
            {currentMode !== "editing" && (
              <CustomButton
                label={
                  currentMode === "creating"
                    ? "View Categories"
                    : "Create Category"
                }
                onClick={() => {
                  currentMode === "creating"
                    ? setCurrentMode("none")
                    : setCurrentMode("creating");
                }}
              />
            )}
            {currentMode === "editing" && (
              <CustomButton
                label={"View Categories"}
                onClick={() => {
                  setCurrentMode("none");
                }}
              />
            )}
          </div>
        </div>
        {currentMode === "none" && (
          <div className="flex flex-wrap w-full  mt-10">
            {data?.map((c) => (
              <div
                key={c.id}
                onClick={() => {
                  setEditingCategory(c);
                  setCurrentMode("editing");
                }}
              >
                <Category {...c} />
              </div>
            ))}
          </div>
        )}
        {currentMode === "editing" && (
          <>
            <span className="text-xl font-semibold">Edit Category</span>
            <label className="text-lg font-semibold antialiased mt-10 ">
              Name
            </label>
            <input
              type="text"
              className="border rounded-md py-2 px-3 w-300"
              value={editingCategory?.name ?? ""}
              placeholder="Digital Innovation"
              onChange={(e) => {
                setEditingCategory({
                  ...editingCategory,
                  name: e.target.value,
                });
              }}
            />
            <label className="text-lg font-semibold antialiased mt-4 ">
              Acronynm
            </label>
            <input
              type="text"
              className="border rounded-md py-2 px-3 w-300"
              value={editingCategory?.acronym ?? ""}
              placeholder="DGI"
              onChange={(e) => {
                setEditingCategory({
                  ...editingCategory,
                  acronym: e.target.value,
                });
              }}
            />
            <label className="text-lg font-semibold antialiased mt-4 ">
              Color
            </label>
            <div className="flex items-center justify-start h-min ">
              <input
                type="text"
                className="border rounded-md py-2 px-3 w-300  mr-2"
                value={editingCategory?.color_hex ?? "#FFFFFF"}
                placeholder="#5e72e4"
                onChange={(e) => {
                  setEditingCategory({
                    ...editingCategory,
                    color_hex: e.target.value,
                  });
                }}
              />
              <InputColor
                initialValue={editingCategory?.color_hex ?? "#5e72e4"}
                onChange={(c) => {
                  setEditingCategory({
                    ...editingCategory,
                    color_hex: c.hex,
                  });
                }}
                placement="right"
              />
            </div>
            <div
              style={{
                width: 320,
                height: 10,
                marginTop: 20,
                backgroundColor: editingCategory?.color_hex ?? "#FFFFFF",
                border: "1px solid black",
                borderRadius: 8,
              }}
            />
            <div className="flex items-center justify-center gap-x-6 mt-8">
              <CustomButton
                tailwindColor="bg-blue-600"
                label={`Save`}
                onClick={() => {
                  sendUpdateCategory(editingCategory!);
                  setCurrentMode("none");
                }}
                disabled={
                  editingCategory?.acronym === "" ||
                  editingCategory?.color_hex === "" ||
                  editingCategory?.name === ""
                }
              />
            </div>
          </>
        )}
        {currentMode === "creating" && (
          <>
            <span className="text-xl font-semibold">Create Category</span>
            <label className="text-lg font-semibold antialiased mt-10 ">
              Name
            </label>
            <input
              type="text"
              className="border rounded-md py-2 px-3 w-300"
              value={creatingCategory.name}
              placeholder="Digital Innovation"
              onChange={(e) => {
                setCreatingCategory({
                  ...creatingCategory,
                  name: e.target.value,
                });
              }}
            />
            <label className="text-lg font-semibold antialiased mt-4 ">
              Acronynm
            </label>
            <input
              type="text"
              className="border rounded-md py-2 px-3 w-300"
              value={creatingCategory.acronym}
              placeholder="DGI"
              onChange={(e) => {
                setCreatingCategory({
                  ...creatingCategory,
                  acronym: e.target.value,
                });
              }}
            />
            <label className="text-lg font-semibold antialiased mt-4 ">
              Color
            </label>
            <div className="flex items-center justify-start h-min ">
              <input
                type="text"
                className="border rounded-md py-2 px-3 w-300  mr-2"
                value={creatingCategory.color_hex}
                placeholder="#5e72e4"
                onChange={(e) => {
                  setCreatingCategory({
                    ...creatingCategory,
                    color_hex: e.target.value,
                  });
                }}
              />
              <InputColor
                initialValue={creatingCategory.color_hex ?? "5e72e4"}
                onChange={(c) => {
                  setCreatingCategory({
                    ...creatingCategory,
                    color_hex: c.hex,
                  });
                }}
                placement="right"
              />
            </div>
            <div
              style={{
                width: 320,
                height: 10,
                marginTop: 20,
                backgroundColor: creatingCategory.color_hex,
                border: "1px solid black",
                borderRadius: 8,
              }}
            />
            <div className="flex items-center justify-center gap-x-6 mt-8">
              <CustomButton
                tailwindColor="bg-blue-600"
                label={`Create Category`}
                onClick={() => {
                  sendCreateCategory(creatingCategory);
                  setCurrentMode("none");
                }}
                disabled={
                  creatingCategory.acronym === "" ||
                  creatingCategory.color_hex === "" ||
                  creatingCategory.name === ""
                }
              />
            </div>
          </>
        )}
      </div>
    </section>
  );
};

const Category = (category: NewsCategory) => {
  return (
    <div
      onClick={() => {}}
      className="h-min flex items-center justify-center  m-4 px-4 py-2 rounded-md hover:bg-gray-500 cursor-pointer bg-black"
    >
      <div className="flex items-center justify-start">
        <div
          style={{
            height: 8,
            width: 8,
            backgroundColor: category.color_hex,
            borderRadius: 1000,
          }}
        ></div>
        <span className="ml-1 text-white">{category.name}</span>
      </div>
    </div>
  );
};

export { ViewNewsCategoriesModal };
