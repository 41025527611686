import React from "react";
import ReactLoading from "react-loading";
import { PieChart, Pie, Cell } from "recharts";
import { NavigatorChart } from "../../../../shared/types";
import { countNodes } from "../../../../utilts/chart_functions";

interface SectionChartProps {
  structure: NavigatorChart | undefined;
}
const SectionsChart: React.FunctionComponent<SectionChartProps> = ({
  structure,
}) => {
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#00C73D"];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    name,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${name} - ${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  if (structure) {
    const data = countNodes(structure?.children);
    return (
      <section
        className="border border-gray-200 bg-black rounded-lg p-4 w-full h-min"
        style={{ cursor: "pointer" }}
      >
        <h2 className="text-white text-lg antialising">
          Section Relations - By quantity
        </h2>
        <PieChart width={340} height={200}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </section>
    );
  }
  return (
    <section
      className="border border-gray-200 bg-black rounded-lg w-full h-min p-16"
      style={{ cursor: "pointer" }}
    >
      <ReactLoading
        type="spinningBubbles"
        color="#FFF"
        height={"10%"}
        width={"10%"}
      />
    </section>
  );
};

export { SectionsChart };
