import MDEditor from "@uiw/react-md-editor";
import React from "react";
import toast from "react-hot-toast";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import ReactLoading from "react-loading";
import {
  ContentRelatedSection,
  NavigatorChart,
  SelectibleNavItem,
} from "../../shared/types";
import { CustomButton } from "../../styles/custom_button";
import {
  findSelectedItems,
  setSelectionNodeById,
} from "../../utilts/chart_functions";
import { BasePage } from "../base";
import { ActionBar } from "./action_bar";
import { NestedSelectibleItem } from "./nested_selectible_item";

import { Container } from "./style";
import { useCreateContentMultiple, useGetChart } from "../../../infra/api";
import { ContentChart } from "../home/components/charts/content_chart";

export type CONTENT_PAGE_STATE_TYPE =
  | "default"
  | "multiple-content__step-1"
  | "multiple-content__step-2"
  | "multiple-content__step-3"
  | "loading";

const ContentPage: React.FunctionComponent = () => {
  const [selectedData, setSelectedData] = React.useState<NavigatorChart | null>(
    null
  );
  const [content, setContent] = React.useState<string | undefined>("");
  const [pageState, setPageState] =
    React.useState<CONTENT_PAGE_STATE_TYPE>("default");
  const [currentNavItem, setCurrentNavItem] =
    React.useState<SelectibleNavItem | null>(null);
  const { isLoading: versionIsLoading, data, isRefetching } = useGetChart("0");
  const { mutateAsync: createMultiple, isLoading: createIsLoading } =
    useCreateContentMultiple();
  let isLoading = versionIsLoading || isRefetching || createIsLoading;

  const selectedItems = React.useCallback(
    () => (selectedData ? findSelectedItems(selectedData) : null),
    [selectedData]
  );

  const _getSelectedItemsIds = React.useCallback((): Array<string> => {
    const selectedItems = findSelectedItems(selectedData!);
    const ids: Array<string> = selectedItems.map((item) => item.id);
    return ids;
  }, [selectedData]);

  const _getContentRelatedSections =
    React.useCallback((): Array<ContentRelatedSection> => {
      const selectedItems = findSelectedItems(selectedData!);
      const relatedSections: Array<any> = selectedItems.map((item) => {
        const relatedSection: any = {
          section_id: item.id,
          name: item.name,
        };
        return relatedSection;
      });
      return relatedSections;
    }, [selectedData]);

  const sendContentMultiple = React.useCallback(async () => {
    setPageState("loading");
    const data = {
      title: "any",
      section_id: "any",
      content: content,
      permissions: ["level_1"],
      section_ids: _getSelectedItemsIds(),
      related_sections: _getContentRelatedSections(),
    };
    createMultiple(data)
      .then(() => {
        toast.success("Content created with success", {
          duration: 3000,
        });
      })
      .catch(() => {
        toast.error("Error creating content", {
          duration: 3000,
        });
      });
    setContent("");
    setPageState("default");
  }, [
    _getContentRelatedSections,
    _getSelectedItemsIds,
    content,
    createMultiple,
  ]);

  function renderBody(): JSX.Element {
    switch (pageState) {
      case "loading":
        return (
          <section
            className="w-fill  flex align-center justify-center "
            style={{
              height: 500,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ReactLoading
              type="cylon"
              color="#000000"
              height={"10%"}
              width={"10%"}
            />
          </section>
        );

      case "multiple-content__step-1":
        return (
          <section className="flex">
            <div className="flex-1 p-4 mt-28">
              <div className="flex items-center justify-between ">
                <h2
                  className=" flex text-xl font-bold  antialiased  pt-8"
                  style={{ marginBottom: 32 }}
                >
                  Select the sections you want to add content to
                </h2>

                <CustomButton
                  label="Next"
                  disabled={selectedItems()?.length === 0}
                  trailing={<FaChevronRight className="ml-2" />}
                  title={
                    selectedItems()?.length === 0
                      ? "Select items to continue"
                      : ""
                  }
                  onClick={() => setPageState("multiple-content__step-2")}
                />
              </div>
              {data &&
                data.children.map((item) => (
                  <NestedSelectibleItem
                    item={item}
                    setCurrentNavItem={setCurrentNavItem}
                    currentNavItem={currentNavItem ?? null}
                    key={item.id}
                    onSelect={(selected, isChecked) => {
                      const updatedData = setSelectionNodeById(
                        data,
                        selected.id,
                        isChecked
                      );
                      setSelectedData(updatedData);
                    }}
                  />
                ))}
            </div>
          </section>
        );

      case "multiple-content__step-2":
        return (
          <section className="w-fill  flex align-center justify-center ">
            <div className="flex-1 p-4 mt-28">
              <div className="flex items-center justify-between ">
                <h2
                  className=" flex text-xl font-bold  antialiased  pt-8"
                  style={{ marginBottom: 32 }}
                >
                  These are your selected items to add content
                </h2>

                <div className="flex gap-4">
                  <CustomButton
                    label="Back"
                    leading={<FaChevronLeft className="mr-2" />}
                    onClick={() => setPageState("multiple-content__step-1")}
                  />
                  <CustomButton
                    label="Next"
                    disabled={selectedItems()?.length === 0}
                    trailing={<FaChevronRight className="ml-2" />}
                    onClick={() => setPageState("multiple-content__step-3")}
                  />
                </div>
              </div>
              {selectedItems()?.map((item) => (
                <div className="flex flex-row items-center justify-between bg-gray-200 p-4 m-4 w-46 border rounded">
                  <span className="font-xl font-semibold antialising">
                    {item.name}
                  </span>
                </div>
              ))}
            </div>
          </section>
        );

      case "multiple-content__step-3":
        return (
          <section className="w-fill  flex flex-col align-center justify-center ">
            <div className="flex-1 p-4 mt-28 flex-col">
              <div className="flex items-center justify-between ">
                <h2
                  className=" flex text-xl font-bold  antialiased  pt-8"
                  style={{ marginBottom: 32 }}
                >
                  Content
                </h2>

                <div className="flex gap-4">
                  <CustomButton
                    label="Back"
                    leading={<FaChevronLeft className="mr-2" />}
                    onClick={() => setPageState("multiple-content__step-2")}
                  />
                  <CustomButton
                    label="Create Content"
                    disabled={content === undefined ? true : false}
                    onClick={sendContentMultiple}
                  />
                </div>
              </div>
            </div>

            <MDEditor
              height={600}
              style={{
                width: "100%",
                height: 2000,
                backgroundColor: "#e5e5e5",
              }}
              value={content}
              onChange={(v) => setContent(v)}
            />
          </section>
        );
      case "default":
        return renderDefaultPage();

      default:
        return <>Something weird happened, please reload the page</>;
    }
  }

  const renderDefaultPage = (): JSX.Element => {
    return (
      <section className="flex items-center justify-center bg-red-500 mt-36">
        <ContentChart structure={data} />
      </section>
    );
  };
  return (
    <BasePage>
      <Container className="dv-structure">
        {pageState !== "loading" && !isLoading && (
          <div
            className=" w-fill fixed z-51 w-full"
            style={{
              marginTop: "-35px",
              marginLeft: "-16px",
              marginBottom: 20,
            }}
          >
            <ActionBar setPageState={setPageState} />
          </div>
        )}
        {renderBody()}
      </Container>
    </BasePage>
  );
};

export { ContentPage };
