import React from "react";
import OutsiderCallbacker from "./components/outside_callbacker";
import { Background, Container } from "./style";
import RadarIcon from "../assets/icons/radar_icon.svg";
import PresentationIcon from "../assets/icons/presentation_icon.svg";
import FeedbackIcon from "../assets/icons/feedback_icon.svg";
import CloseIcon from "../assets/icons/close_icon.svg";
import { ReactComponent as ArrowRight } from "../assets/icons/right_arrow_icon.svg";

interface ModalProps {
  height?: number;
  width?: number;
  children?: React.ReactNode;
  padding?: number;
  title: string;
  showArrow: boolean;
  onClickArrow: () => void;
  onClickRadar: () => void;
  onClickPresentation: () => void;
  onClickClose: () => void;
  onClickFeedback: () => void;
}

const Modal: React.FunctionComponent<ModalProps> = ({
  height,
  width,
  children,
  padding,
  title,
  showArrow,
  onClickArrow,
  onClickRadar,
  onClickPresentation,
  onClickClose,
  onClickFeedback,
}) => {
  return (
    <Background>
      <OutsiderCallbacker clickOutsideEvent={() => null}>
        <Container
          height={height}
          width={width}
          padding={padding}
          className="modal-container"
        >
          <div className="top-header">
            <div className="buttons">
              <div
                className="icon-holder"
                title="Close"
                onClick={() => onClickClose()}
              >
                <img
                  src={CloseIcon}
                  alt="a"
                  style={{ width: 20, height: 25 }}
                />
              </div>
              <div
                className="icon-holder"
                title="Feedback"
                onClick={async () => {
                  window.open("https://google.com", "_blank");

                  return onClickFeedback();
                }}
              >
                <img
                  src={FeedbackIcon}
                  alt="a"
                  style={{ width: 25, height: 25 }}
                />
              </div>

              <div
                className="icon-holder"
                title="Radar"
                onClick={() => {
                  window.open("https://google.com", "_blank");

                  return onClickRadar();
                }}
              >
                <img
                  src={RadarIcon}
                  alt="a"
                  style={{ width: 25, height: 25 }}
                />
              </div>
              <div
                className="icon-holder"
                title="Presentation"
                onClick={() => {
                  window.open("https://google.com", "_blank");

                  return onClickPresentation();
                }}
              >
                <img
                  src={PresentationIcon}
                  alt="a"
                  style={{ width: 25, height: 25 }}
                />
              </div>
            </div>

            <div className="buttons">
              {showArrow && (
                <div className="arrow-holder" onClick={() => onClickArrow()}>
                  <ArrowRight fill="white" height={30} width={30} />
                </div>
              )}
            </div>
          </div>
          <div className="header">
            <p>{title}</p>
          </div>

          {children}
        </Container>
      </OutsiderCallbacker>
    </Background>
  );
};

export default Modal;
