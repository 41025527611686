import React, { useEffect, useState } from "react";
import {
  FaCheck,
  FaEyeSlash,
  FaRegCaretSquareDown,
  FaRegCaretSquareRight,
} from "react-icons/fa";
import { ContentStatusInfo, NavItem } from "../../../../shared/types";
import { FaClock } from "react-icons/fa";
import { FaPencilAlt } from "react-icons/fa";

interface NavItemProps {
  item: NavItem;
  setCurrentNavItem: React.Dispatch<React.SetStateAction<NavItem | null>>;
  currentNavItem: NavItem | null;
  statusData?: ContentStatusInfo[] | undefined;
  showStatus?: boolean;
  movingItemId?: string;
  expandedItems: Set<string>;
  setExpandedItems: React.Dispatch<React.SetStateAction<Set<string>>>;
}

const NestedItem: React.FunctionComponent<NavItemProps> = ({
  item,
  setCurrentNavItem,
  currentNavItem,
  statusData,
  showStatus = true,
  movingItemId = "",
  expandedItems,
  setExpandedItems,
}) => {
  const [currentStatus, setCurrentStatus] = useState<number>(0);

  const isExpanded = expandedItems.has(item.id);

  const toggleExpand = () => {
    if (isExpanded) {
      setExpandedItems((prevExpandedItems) => {
        const newExpandedItems = new Set(prevExpandedItems);
        newExpandedItems.delete(item.id);
        return newExpandedItems;
      });
    } else {
      setExpandedItems((prevExpandedItems) => {
        const newExpandedItems = new Set(prevExpandedItems);
        newExpandedItems.add(item.id);
        return newExpandedItems;
      });
    }
  };

  const isEqual = currentNavItem?.id === item.id;

  useEffect(() => {
    if (statusData !== undefined) {
      const status = statusData.find((node) => node.id === item?.content_id);
      if (status) setCurrentStatus(status.status);
    }
  }, [item, statusData]);

  return (
    <div style={{ marginLeft: 20 }} key={item.id}>
      <div
        className="w-96 flex items-center  justify-start  hover:text-gray-600 hover:border-red hover:bg-pink-100"
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        onClick={() => {
          setCurrentNavItem(item);
          return toggleExpand();
        }}
      >
        {item.children && (
          <>
            {isExpanded ? <FaRegCaretSquareDown /> : <FaRegCaretSquareRight />}
          </>
        )}
        <p
          className={isEqual ? "text-pink-600" : "text-black"}
          style={{ marginLeft: "4px", fontSize: "1.2em" }}
        >
          {item.name}
        </p>
        {!item.is_visible && (
          <FaEyeSlash
            className="text-gray-400"
            style={{ padding: 10 }}
            size={40}
            title="This section is hidden for the public"
          />
        )}
        {item.content_id && showStatus && currentStatus === 2 && (
          <FaCheck
            className="text-green-400 ml-2"
            size={12}
            title="This section content is APPROVED"
          />
        )}
        {item.content_id && showStatus && currentStatus === 1 && (
          <FaClock
            className="text-orange-400 ml-2"
            size={12}
            title="This section content is AWAITING APPROVAL"
          />
        )}
        {item.content_id && showStatus && currentStatus === 0 && (
          <FaPencilAlt
            className="text-yellow-400 ml-2"
            size={12}
            title="This section content is IN PROGRESS"
          />
        )}
      </div>
      {isExpanded && item.children && (
        <div>
          {item.children.map(
            (child, index) =>
              movingItemId !== child.id && (
                <NestedItem
                  item={child}
                  setCurrentNavItem={setCurrentNavItem}
                  currentNavItem={currentNavItem}
                  statusData={statusData}
                  expandedItems={expandedItems}
                  setExpandedItems={setExpandedItems}
                  key={item.id + index}
                  showStatus={showStatus}
                  movingItemId={movingItemId}
                />
              )
          )}
        </div>
      )}
    </div>
  );
};

export { NestedItem };
