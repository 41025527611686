import React from "react";
import { toast } from "react-hot-toast";
import ReactLoading from "react-loading";

import Select, { InputActionMeta } from "react-select";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import MDEditor from "@uiw/react-md-editor";
import {
  useGetNewsTags,
  useGetNewsCategories,
  useGetChart,
  useCreateNews,
} from "../../../infra/api";
import { NewsItem, LinkType } from "../../../utils/types/news-radar";
import { NavItem, NavigatorChart } from "../../shared/types";
import { CustomButton } from "../../styles/custom_button";

type OptionType = {
  label: string;
  value: string;
};
interface SelectableOption {
  label: string;
  name: string;
  value: string;
}

const EMPTY_NEWS: Partial<NewsItem> = {
  title: "",
  content: "",
  description: "",
  is_important: false,
  tags_ids: [],
  news_categories_ids: [],
  notice_date: "",
  related_nav_sections: [],
  links: [],
};
interface CreateNewsModalProps {
  onCreate: () => void;
}
const CreateNewsModal: React.FunctionComponent<CreateNewsModalProps> = ({
  onCreate,
}) => {
  const [newsFormData, setNewsFormData] =
    React.useState<Partial<NewsItem>>(EMPTY_NEWS);

  const [formErrors, setFormErrors] = React.useState<Array<string>>([]);
  const [selectedTagsIDs, setSelectedTagsIDs] = React.useState<OptionType[]>(
    []
  );
  const [selectedNavNodesIDs, setSelectedNavNodesIDs] = React.useState<
    OptionType[]
  >([]);

  const [selectedCategory, setSelectedCategory] =
    React.useState<OptionType | null>(null);
  const [selectNavigatorOptions, setSelectNavigatorOptions] = React.useState<
    SelectableOption[]
  >([]);
  const [currentWhitePaper, setCurrentWhitePaper] = React.useState<{
    title: string;
    link: string;
  }>({ title: "", link: "" });
  const [whitepapers, setWhitePapers] = React.useState<
    Array<{ title: string; link: string }>
  >([]);
  const [currentPrimer, setCurrentPrimer] = React.useState<{
    title: string;
    link: string;
  }>({ title: "", link: "" });
  const [primers, setPrimers] = React.useState<
    Array<{ title: string; link: string }>
  >([]);

  const createOptions = React.useCallback(
    (node: NavItem): SelectableOption[] => {
      const options: SelectableOption[] = [];
      options.push({
        name: node.name,
        value: node.id,
        label: node.name,
      });
      if (node.children) {
        const childrenOptions = node.children.map((child) => {
          const childOptions = createOptions(child);
          childOptions.forEach((option) => {
            if (option) {
              option.label = node.name + "/" + option.label;
              if (option.label.length > 60) {
                const endOfOption = option.label.match(/\/([^/]+\/[^/]+)\/?$/g);
                if (endOfOption !== null) option.label = "..." + endOfOption[0];
              }
            }
          });
          return childOptions;
        });
        childrenOptions.forEach((childOptions) => {
          options.push(...childOptions);
        });
      }
      return options;
    },
    []
  );
  const getSelectableOptions = React.useCallback(
    (chart: NavigatorChart) => {
      const selectableOptions: SelectableOption[] = [];
      const selectableOptionsByChildren = chart.children.map((child) => {
        return createOptions(child);
      });
      selectableOptionsByChildren.forEach((childOptions) => {
        selectableOptions.push(...childOptions);
      });
      return selectableOptions;
    },
    [createOptions]
  );
  const {
    isLoading: tagsIsLoading,
    data: tags,
    isRefetching: tagsIsRefetching,
  } = useGetNewsTags();
  const {
    isLoading: categoriesIsLoading,
    data: categories,
    isRefetching: categoriesIsRefetching,
  } = useGetNewsCategories();
  const {
    isLoading: chartIsLoading,
    data: chartData,
    isRefetching: chartIsRefetching,
  } = useGetChart("0");

  const { mutateAsync: createNews, isLoading: creationIsLoading } =
    useCreateNews();

  const isLoading =
    tagsIsLoading ||
    tagsIsRefetching ||
    categoriesIsLoading ||
    categoriesIsRefetching ||
    chartIsRefetching ||
    creationIsLoading ||
    chartIsLoading;
  const tagsOptions =
    tags?.map((tag) => ({ value: tag.id, label: tag.title })) ?? [];

  const categoriesOptions = categories?.map((c) => ({
    value: c.id,
    label: c.name,
  }));

  const sendCreateNews = React.useCallback(
    async (news: Partial<NewsItem>) => {
      createNews(news).then(() => {
        toast.success("Created News with success", {
          duration: 1000,
        });
        onCreate();
      });
    },
    [createNews, onCreate]
  );

  const handleTagChange = (selectedOptions: any) => {
    if (selectedOptions) {
      setSelectedTagsIDs(selectedOptions);
    } else {
      setSelectedTagsIDs([]);
    }
  };
  const handleCategoryChange = (selectedOption: OptionType | null) => {
    setSelectedCategory(selectedOption);
  };
  const handleNavigatorNodesChange = (selectedOptions: any) => {
    if (selectedOptions) {
      setSelectedNavNodesIDs(selectedOptions);
    } else {
      setSelectedNavNodesIDs([]);
    }
  };
  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    setNewsFormData((prevFormData) => {
      if (type === "checkbox") {
        return { ...prevFormData, [name]: checked };
      } else {
        return { ...prevFormData, [name]: value };
      }
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  };

  const couldProceed = (): boolean => {
    const errors = [];

    if (newsFormData.title === "") {
      errors.push("Title could not be empty.");
    }
    if (newsFormData.description === "") {
      errors.push("Description could not be empty.");
    }
    if (newsFormData.content === "") {
      errors.push("Content could not be empty.");
    }
    if (newsFormData.notice_date === "") {
      errors.push("Notice Date could not be empty.");
    }
    if (selectedCategory === undefined || selectedCategory === null) {
      errors.push("Category could not be empty.");
    }

    if (selectedTagsIDs.length === 0) {
      errors.push("Tags could not be empty.");
    }

    setFormErrors(errors);

    if (errors.length !== 0) return false;
    return true;
  };

  const renderErrors = () => {
    return (
      <div className="flex flex-col items-start justify-center mb-8">
        {formErrors.map((er) => (
          <span key={er} className="text-red-500 font-semibold my-1 ">
            ● {er}
          </span>
        ))}
      </div>
    );
  };
  React.useEffect(() => {
    if (chartData !== undefined) {
      const selectableOptions = getSelectableOptions(chartData);
      setSelectNavigatorOptions(selectableOptions);
    }
  }, [chartData, getSelectableOptions]);
  return isLoading ? (
    <section
      style={{ width: "80vw", height: "90%" }}
      className=" flex items-center justify-center"
    >
      <ReactLoading type="cylon" color="#000000" height={"10%"} width={"10%"} />
    </section>
  ) : (
    <section
      style={{ width: "80vw", height: "90%", overflowY: "auto" }}
      className=" flex items-start justify-start"
    >
      <div className="flex flex-col items-start justify-center p-5 w-full align-content-start">
        <div className="flex items-start gap-6 pt-1 flex-wrap">
          <h1 className="text-2xl font-semibold antialiased uppercase">
            CREATE NEWS
          </h1>
        </div>
        <form
          onSubmit={handleSubmit}
          className="w-full flex flex-col items-start justify-center"
        >
          <label
            className="text-black antialising text-normal font-semibold"
            htmlFor="firstName"
          >
            Title
          </label>
          <input
            type="text"
            name="title"
            value={newsFormData.title}
            onChange={handleFormChange}
            className={`bg-gray-200 mt-1 mb-4 w-full h-12 rounded-3xl pl-4  focus:border-2 focus:border-black ${
              //   errors.firstName
              //     ? "border-2 border-red-500" :
              "bg-black text-black"
            }`}
          />
          <label
            className="text-black antialising text-normal font-semibold"
            htmlFor="firstName"
          >
            Description
          </label>
          <input
            type="text"
            name="description"
            value={newsFormData.description}
            onChange={handleFormChange}
            className={`bg-gray-200 mt-1 mb-4 w-full h-12 rounded-3xl pl-4  focus:border-2 focus:border-black ${"bg-black text-black"}`}
          />
          <label
            className="text-black antialising text-normal font-semibold mb-2"
            htmlFor="firstName"
          >
            Content
          </label>
          <MDEditor
            height={400}
            style={{
              width: "100%",
              height: 2000,
              backgroundColor: "#e5e5e5",
              marginBottom: 8,
            }}
            value={newsFormData.content}
            onChange={(v) => setNewsFormData({ ...newsFormData, content: v })}
          />
          <label
            className="text-black antialising text-normal font-semibold"
            htmlFor="firstName"
          >
            Notice Date
          </label>
          <input
            type="date"
            name="notice_date"
            value={newsFormData.notice_date}
            onChange={handleFormChange}
            className={`bg-gray-200 mt-1 mb-4 w-40 h-12 rounded-3xl pl-4 pr-4  focus:border-2 focus:border-black ${"bg-black text-black"}`}
          />

          <label className="text-lg font-semibold antialiased">Priority?</label>
          <input
            type="checkbox"
            name="is_important"
            className="form-checkbox h-5 w-5 accent-pink-600 ml-2 mb-4"
            checked={newsFormData.is_important}
            onChange={handleFormChange}
          />
        </form>
        <label className="text-lg font-semibold antialiased">Tags</label>
        <div className="w-full">
          <Select
            isMulti
            name="tags"
            options={tagsOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={handleTagChange}
            value={selectedTagsIDs}
            inputValue={""}
            onInputChange={function (
              newValue: string,
              actionMeta: InputActionMeta
            ): void {}}
            onMenuOpen={function (): void {}}
            onMenuClose={function (): void {}}
          />
          <div className="my-2" />
          <label className="text-lg font-semibold antialiased">Category</label>
          <div className="w-full mb-4">
            <Select
              name="categories"
              options={categoriesOptions}
              className="basic-single-select"
              classNamePrefix="select"
              onChange={handleCategoryChange}
              value={selectedCategory}
            />
          </div>
          <label className="text-lg font-semibold antialiased">
            Navigator Sections
          </label>

          <div className="w-full">
            <Select
              isMulti
              name="navigator_nodes_ids"
              options={selectNavigatorOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleNavigatorNodesChange}
              value={selectedNavNodesIDs}
              inputValue={""}
              onInputChange={function (
                newValue: string,
                actionMeta: InputActionMeta
              ): void {}}
              onMenuOpen={function (): void {}}
              onMenuClose={function (): void {}}
            />
          </div>
        </div>

        <div className="flex flex-col items-start justify-center w-full mt-4">
          <label className="text-lg font-semibold antialiased">
            White Papers
          </label>
          <div className="flex items-center justify-start w-full ">
            <input
              type="text"
              name="whitepaper_title"
              value={currentWhitePaper.title}
              placeholder="Title"
              onChange={(e) => {
                setCurrentWhitePaper({
                  ...currentWhitePaper,
                  title: e.target.value,
                });
              }}
              className={`mr-2 bg-gray-200 mt-1 mb-4 w-1/4 h-12 rounded-3xl px-4  focus:border-2 focus:border-black ${"bg-black text-black"}`}
            />
            <input
              type="text"
              name="whitepaper_link"
              value={currentWhitePaper.link}
              placeholder="https://example.com"
              onChange={(e) => {
                setCurrentWhitePaper({
                  ...currentWhitePaper,
                  link: e.target.value,
                });
              }}
              className={`bg-gray-200 mt-1 mb-4 w-1/4 h-12 rounded-3xl px-4  focus:border-2 focus:border-black ${"bg-black text-black"}`}
            />

            <FaPlusCircle
              className="ml-2 mb-2  cursor-pointer"
              size={30}
              onClick={() => {
                if (
                  currentWhitePaper.link === "" ||
                  currentWhitePaper.title === ""
                )
                  return;
                setWhitePapers([...whitepapers, currentWhitePaper]);
              }}
            />
          </div>

          <div className="flex flex-col items-start justify-center px-2 ml-4">
            {whitepapers.map((w) => (
              <div
                className="flex flex-row items-center justify-start bg-gray-200 px-4 py-2 hover:bg-gray-500 my-1 rounded-xl cursor-pointer"
                title="Remove item"
                key={w.title}
                onClick={() => {
                  setWhitePapers(whitepapers.filter((wpp) => w !== wpp));
                }}
              >
                <span>
                  {w.title} - {w.link}
                </span>
                <FaMinusCircle
                  className="ml-2  cursor-pointer"
                  size={20}
                  onClick={() => {
                    setWhitePapers([...whitepapers, currentWhitePaper]);
                  }}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col items-start justify-center w-full mt-4">
          <label className="text-lg font-semibold antialiased">Primers</label>
          <div className="flex items-center justify-start w-full ">
            <input
              type="text"
              name="primer_title"
              value={currentPrimer.title}
              placeholder="https://example.com"
              onChange={(e) => {
                setCurrentPrimer({ ...currentPrimer, title: e.target.value });
              }}
              className={`mr-2 bg-gray-200 mt-1 mb-4 w-1/4 h-12 rounded-3xl px-4  focus:border-2 focus:border-black ${"bg-black text-black"}`}
            />
            <input
              type="text"
              name="primer_link"
              value={currentPrimer.link}
              placeholder="https://example.com"
              onChange={(e) => {
                setCurrentPrimer({ ...currentPrimer, link: e.target.value });
              }}
              className={`bg-gray-200 mt-1 mb-4 w-1/4 h-12 rounded-3xl px-4  focus:border-2 focus:border-black ${"bg-black text-black"}`}
            />

            <FaPlusCircle
              className="ml-2 mb-2 hover:text-gray-500 cursor-pointer"
              size={30}
              onClick={() => {
                if (currentPrimer.link === "" || currentPrimer.title === "")
                  return;

                setPrimers([...primers, currentPrimer]);
              }}
            />
          </div>

          <div className="flex flex-col items-start justify-center px-2 ml-4">
            {primers.map((p) => (
              <div
                className="flex flex-row items-center justify-start bg-gray-200 px-4 py-2 hover:bg-gray-500 my-1 rounded-xl cursor-pointer"
                title="Remove item"
                key={p.title}
                onClick={() => {
                  setPrimers(primers.filter((primer) => p !== primer));
                }}
              >
                <span>
                  {p.title} - {p.link}
                </span>
                <FaMinusCircle
                  className="ml-2 hover:text-gray-500 cursor-pointer"
                  size={20}
                  onClick={() => {
                    setPrimers(primers.filter((primer) => p !== primer));
                  }}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="my-4" />
        {renderErrors()}
        <CustomButton
          label="CREATE"
          onClick={() => {
            if (!couldProceed()) {
              return;
            }
            const buildRelatedNavSections = selectedNavNodesIDs.map((nv) => ({
              section_id: nv.value,
              name: nv.label.split("/").pop() ?? nv.label,
            }));
            const whitePaperLinks: Array<LinkType> = whitepapers.map((w) => ({
              text: w.title,
              type: "whitepaper",
              url: w.link,
            }));
            const primerLinks: Array<LinkType> = primers.map((w) => ({
              text: w.title,
              type: "primer",
              url: w.link,
            }));

            const buildLinks: Array<LinkType> = [
              ...whitePaperLinks,
              ...primerLinks,
            ];

            const createNewsObject: Partial<NewsItem> = {
              title: newsFormData.title,
              description: newsFormData.description,
              content: newsFormData.content,
              notice_date: formatDateToCreateNews(
                newsFormData.notice_date ?? "1990-01-01"
              ),
              is_important: newsFormData.is_important ?? false,
              tags_ids: selectedTagsIDs.map((t) => t.value),
              news_categories_ids:
                selectedCategory !== undefined ? [selectedCategory!.value] : [],
              related_nav_sections: buildRelatedNavSections,
              links: buildLinks,
            };

            sendCreateNews(createNewsObject);
          }}
        />
      </div>
    </section>
  );
};
function formatDateToCreateNews(dateString: string): string {
  const date = new Date(dateString);
  let isoString = date.toISOString();
  isoString = isoString.slice(0, -5) + "Z";

  return isoString;
}

export { CreateNewsModal };
