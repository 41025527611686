import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import React from "react";
import { SigninPage } from "../pages/signin";
import ReactLoading from "react-loading";
import { useAuth } from "../../contexts/auth_provider";

const ProtectedRoute: React.FunctionComponent<{ children: React.ReactNode }> = (
  props
) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isLogged, setIsLogged] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    if (user === null) {
      navigate("/signin");
      setIsLogged(false);
    } else {
      setIsLogged(true);
    }
    setIsLoading(false);
  }, [navigate, user]);

  if (isLoading) {
    return (
      <div className="h-56 w-full flex items-center justify-center">
        <ReactLoading
          type="cylon"
          color="#000000"
          height={"10%"}
          width={"10%"}
        />
      </div>
    );
  }

  return isLogged ? <>{props.children}</> : <SigninPage />;
};
export { ProtectedRoute };
