import React, { useState } from "react";
import { toast } from "react-hot-toast";
import ReactLoading from "react-loading";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { User } from "../../../../utils/types/user";
import { CustomButton } from "../../../styles/custom_button";
import { useCreateUser } from "../../../../infra/api";
import { UserRequest } from "../../../shared/types/user";

interface ViewUserModalProps {
  onClose: () => void;
  item: User | null;
  onChangeStructure: () => void;
}

const ViewUserModal: React.FunctionComponent<ViewUserModalProps> = ({
  item,
  onChangeStructure,
  onClose,
}) => {
  const emptyUser: UserRequest = {
    email: "",
    first_name: "",
    last_name: "",
    role: "leader",
    password: "",
  };

  const [creatingUser, setCreatingUser] =
    React.useState<UserRequest>(emptyUser);
  const [showPassword, setShowPassword] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errors, _] = useState({
    email: "",
    password: "",
  });
  const {
    mutateAsync: createUser,
    isLoading: creationIsLoading,
    isError: creationIsError,
  } = useCreateUser();
  const isError = creationIsError;
  const isLoading = creationIsLoading;

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const sendCreateUser = React.useCallback(async () => {
    createUser(creatingUser).then(() => {
      toast.success("Created child with success", {
        duration: 1000,
      });
      onChangeStructure();
    });
  }, [createUser, creatingUser, onChangeStructure]);

  const hasError = () => {
    return (
      isError && (
        <span className="text-xl font-semibold text-red-500">
          Something Wrong Happened, please try again.
        </span>
      )
    );
  };

  return isLoading ? (
    <section
      style={{ width: "50vw", height: "90%" }}
      className=" flex items-center justify-center"
    >
      <ReactLoading type="cylon" color="#000000" height={"10%"} width={"10%"} />
    </section>
  ) : (
    <section
      style={{ width: "50vw", height: "90%" }}
      className=" flex items-start justify-start overflow-auto"
    >
      <div
        className="flex flex-col items-start justify-center p-5 max-h-full w-full align-content-start"
        style={{ overflowY: "auto" }}
      >
        {/* Modal content goes here */}
        <div className="flex items-start gap-6 pt-1 flex-wrap">
          <h1 className="text-2xl font-semibold antialiased uppercase">
            Create user
          </h1>
        </div>
        <>
          <label className="text-lg font-semibold antialiased mt-10 ">
            Email
          </label>
          <input
            type="text"
            className="border rounded-md py-2 px-3 mb-6 w-300"
            value={creatingUser.email}
            onChange={(e) =>
              setCreatingUser({ ...creatingUser, email: e.target.value })
            }
          />
          <div className="flex items-start gap-6 pt-1 flex-wrap">
            <div className="flex items-start gap-6 pt-1 flex-wrap">
              <label className="text-lg font-semibold antialiased mt-2">
                First name
              </label>
              <input
                type="text"
                className="border rounded-md py-2 px-3 mb-6 w-300"
                value={creatingUser.first_name}
                onChange={(e) =>
                  setCreatingUser({
                    ...creatingUser,
                    first_name: e.target.value,
                  })
                }
              />
            </div>
            <div className="flex items-start gap-6 pt-1 flex-wrap">
              <label className="text-lg font-semibold antialiased mt-2 ">
                Last name
              </label>
              <input
                type="text"
                className="border rounded-md py-2 px-3 mb-6 w-300"
                value={creatingUser.last_name}
                onChange={(e) =>
                  setCreatingUser({
                    ...creatingUser,
                    last_name: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <label
            htmlFor="password"
            className="text-lg font-semibold antialiased mt-2 "
          >
            Password
          </label>
          <div className="relative w-full">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={creatingUser.password}
              onChange={(e) =>
                setCreatingUser({ ...creatingUser, password: e.target.value })
              }
              className={`border rounded-md py-2 px-3 mb-6 w-300 ${
                errors.password
                  ? "border-2 border-red-500"
                  : "bg-white text-black"
              }`}
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute left-60 top-2 p-1"
            >
              {showPassword ? (
                <FaEyeSlash size={20} className="text-black" />
              ) : (
                <FaEye size={20} className="text-black" />
              )}
            </button>
          </div>
          <label className="text-lg font-semibold antialiased mt-2 ">
            Role
          </label>
          <select
            defaultValue={"leader"}
            onChange={(e) => {
              setCreatingUser({ ...creatingUser, role: e.target.value });
            }}
            className="bg-slate-500 text-white mb-6 text-sm rounded-lg font-semibold antialising  p-3 cursor-pointer h-min "
          >
            <option value={"leader"} style={{ width: 400 }}>
              {"Content Leader"}
            </option>
            <option value={"producer"} style={{ width: 400 }}>
              {"Content Producer"}
            </option>
          </select>
          <div className="flex items-center justify-center gap-x-6">
            <CustomButton
              tailwindColor="bg-blue-600"
              label={`Create user`}
              onClick={() => sendCreateUser()}
            />
            <CustomButton
              tailwindColor="bg-red-500"
              label="Cancel"
              onClick={() => {
                setCreatingUser(emptyUser);
                onClose();
              }}
            />
          </div>
        </>
        {hasError()}
      </div>
    </section>
  );
};

export { ViewUserModal };
