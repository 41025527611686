import React from "react";
import ReactLoading from "react-loading";
import {
  Legend,
  Bar,
  BarChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useGetContentStatus } from "../../../../../infra/api";
import { NavigatorChart } from "../../../../shared/types";
import { processStatus } from "../../../../utilts/utils";
interface ContentChartProps {
  structure: NavigatorChart | undefined;
}
const ContentChart: React.FunctionComponent<ContentChartProps> = ({
  structure,
}) => {
  const { data: statusData } = useGetContentStatus();

  if (statusData && structure) {
    const processedStatusData = processStatus(structure.children, statusData);

    const data = Object.entries(processedStatusData).map(
      ([name, statusCounts]) => {
        return {
          name,
          ...statusCounts,
        };
      }
    );
    return (
      <section
        className="border border-gray-200 bg-black rounded-lg p-4 w-full h-min"
        style={{ cursor: "pointer" }}
      >
        <h2 className="text-white text-lg antialising mb-4">
          Content Approval Relation
        </h2>
        <BarChart
          width={700}
          height={500}
          data={data}
          layout="horizontal"
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="Approved" fill="#82ca9d" />
          <Bar dataKey="In Progress" fill="#706209" />
          <Bar dataKey="Awaiting Approval" fill="#b04120" />
          <Bar dataKey="No Content" fill="red" />
        </BarChart>
      </section>
    );
  }

  return (
    <section
      className="border border-gray-200 bg-black rounded-lg p-4 w-full h-min p-16"
      style={{ cursor: "pointer" }}
    >
      <ReactLoading
        type="spinningBubbles"
        color="#FFF"
        height={"10%"}
        width={"10%"}
      />
    </section>
  );
};

export { ContentChart };
