import MDEditor from "@uiw/react-md-editor";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import ReactLoading from "react-loading";
import Select from "react-select";
import {
  useGetContent,
  useUpdateContent,
  useDeleteContent,
  useCreateContent,
  useApproveContent,
  useContentStatusChange,
  useDetachSectionContent,
} from "../../../infra/api";
import {
  NavItem,
  NavigatorChart,
  NavigatorContent,
  ChangeStatusInterface,
  ContentStatusDict,
} from "../../shared/types";
import { CustomButton } from "../../styles/custom_button";
import { ConfirmationModal } from "./confirmation_modal";
import { Modal } from "./modal";

interface ViewSectionModalProps {
  onClose: () => void;
  item: NavItem;
  chart: NavigatorChart | undefined;
  onChangeStructure: () => void;
}

interface SelectableOption {
  label: string;
  name: string;
  value: string;
}

const ViewContentModal: React.FunctionComponent<ViewSectionModalProps> = ({
  item,
  onChangeStructure,
  chart,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectOptions, setSelectOptions] = useState<SelectableOption[]>([]);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  React.useState<boolean>(false);
  const { isLoading: contentIsLoading, data } = useGetContent(item.content_id);
  const { mutateAsync: updateContent, isLoading: updateIsLoading } =
    useUpdateContent();
  const { mutateAsync: deleteContent, isLoading: deleteIsLoading } =
    useDeleteContent();
  const { mutateAsync: createContent, isLoading: createIsLoading } =
    useCreateContent();
  const { mutateAsync: approveContent, isLoading: approveIsLoading } =
    useApproveContent();
  const { mutateAsync: statusChange, isLoading: statusIsLoading } =
    useContentStatusChange();
  const { mutateAsync: detachContent, isLoading: detachIsLoading } =
    useDetachSectionContent();

  let isLoading =
    contentIsLoading ||
    updateIsLoading ||
    createIsLoading ||
    approveIsLoading ||
    detachIsLoading ||
    deleteIsLoading ||
    statusIsLoading;
  const [editedContent, setEditedContent] = React.useState<string | undefined>(
    ""
  );
  const [currentStatus, setCurrentStatus] = React.useState<number | undefined>(
    0
  );

  const createOptions = useCallback((node: NavItem): SelectableOption[] => {
    const options: SelectableOption[] = [];
    options.push({
      name: node.name,
      value: node.id,
      label: node.name,
    });
    if (node.children) {
      const childrenOptions = node.children.map((child) => {
        const childOptions = createOptions(child);
        childOptions.forEach((option) => {
          if (option) {
            option.label = node.name + "/" + option.label;
            if (option.label.length > 60) {
              const endOfOption = option.label.match(/\/([^/]+\/[^/]+)\/?$/g);
              if (endOfOption !== null) option.label = "..." + endOfOption[0];
            }
          }
        });
        return childOptions;
      });
      childrenOptions.forEach((childOptions) => {
        options.push(...childOptions);
      });
    }
    return options;
  }, []);

  const getSelectableOptions = useCallback(
    (chart: NavigatorChart) => {
      const selectableOptions: SelectableOption[] = [];
      const selectableOptionsByChildren = chart.children.map((child) => {
        return createOptions(child);
      });
      selectableOptionsByChildren.forEach((childOptions) => {
        selectableOptions.push(...childOptions);
      });
      return selectableOptions;
    },
    [createOptions]
  );

  useEffect(() => {
    if (data !== undefined) {
      setEditedContent(data.to_approve);
      setCurrentStatus(data.status);
    }
    if (chart !== undefined) {
      const selectableOptions = getSelectableOptions(chart);
      setSelectOptions(selectableOptions);
    }
  }, [chart, data, getSelectableOptions]);

  const handleCloseModal = React.useCallback(() => {
    setShowConfirmationModal(false);
    setShowDeleteConfirmationModal(false);
  }, []);

  const sendContent = React.useCallback(async () => {
    if (item.content_id) {
      let updatedContent = { ...data };
      updatedContent.content = editedContent;
      updateContent(updatedContent)
        .then(() => {
          toast.success("Updated with success", {
            duration: 1000,
          });
          onChangeStructure();
        })
        .catch(() => {
          toast.error("Error - Something wrong happened", {
            duration: 1000,
          });
        });
    } else {
      let contentToCreate: Partial<NavigatorContent> = { ...data };
      contentToCreate.section_id = item.id;
      contentToCreate.title = item.name;
      contentToCreate.content = editedContent;
      contentToCreate.permissions = ["level_1"];
      createContent(contentToCreate)
        .then(() => {
          toast.success("Created with success", {
            duration: 1000,
          });
          onChangeStructure();
        })
        .catch(() => {
          toast.error("Error - Something wrong happened", {
            duration: 1000,
          });
        });
    }
  }, [
    createContent,
    data,
    editedContent,
    item,
    onChangeStructure,
    updateContent,
  ]);

  const sendDeleteContent = React.useCallback(async () => {
    if (item.content_id) {
      deleteContent(item.content_id)
        .then(() => {
          toast.success("Updated with success", {
            duration: 1000,
          });
          onChangeStructure();
        })
        .catch(() => {
          toast.error("Error - Something wrong happened", {
            duration: 1000,
          });
        });
    }
  }, [deleteContent, item.content_id, onChangeStructure]);

  const sendStatusUpdate = React.useCallback(
    async (status: number) => {
      if (data && currentStatus !== undefined) {
        var request: ChangeStatusInterface = {
          contentId: data.id,
          body: {
            status: status,
          },
        };
        statusChange(request)
          .then(() => {
            toast.success("Status updated with success", {
              duration: 1000,
            });
          })
          .catch(() => {
            toast.error("Error - Something wrong happened", {
              duration: 1000,
            });
          });
      }
    },
    [currentStatus, data, statusChange]
  );

  const sendContentApproval = React.useCallback(async () => {
    if (data) {
      approveContent(data.id)
        .then(() => {
          toast.success("Approved with success", {
            duration: 1000,
          });
          onChangeStructure();
        })
        .catch(() => {
          toast.error("Error - Something wrong happened", {
            duration: 1000,
          });
        });
    }
  }, [approveContent, data, onChangeStructure]);

  const sendDetachAction = React.useCallback(async () => {
    detachContent(item.id)
      .then(() => {
        toast.success("Approved with success", {
          duration: 1000,
        });
        onChangeStructure();
      })
      .catch(() => {
        toast.error("Error - Something wrong happened", {
          duration: 1000,
        });
      });
  }, [detachContent, item.id, onChangeStructure]);

  React.useEffect(() => {
    if (contentRef.current) {
      const contentHeight = contentRef.current.scrollHeight;
      const modalHeight = window.innerHeight * 0.8;
      if (contentHeight > modalHeight) {
        contentRef.current.classList.add("overflow-y-scroll");
      } else {
        contentRef.current.classList.remove("overflow-y-scroll");
      }
    }
  }, []);

  const changeContentStatus = (key: string) => {
    if (Number(key) !== 2) {
      sendStatusUpdate(Number(key));
    }
  };

  const getStatusEnum = () => {
    // eslint-disable-next-line array-callback-return
    var options = Object.keys(ContentStatusDict).map((key) => {
      if (key !== "Approved") {
        return (
          <option key={key} value={ContentStatusDict[key]}>{`${key}`}</option>
        );
      }
    });
    return options;
  };

  return isLoading ? (
    <section
      style={{ width: "80vw", height: "90%" }}
      className=" flex items-center justify-center"
    >
      <ReactLoading type="cylon" color="#000000" height={"10%"} width={"10%"} />
    </section>
  ) : (
    <section
      style={{ width: "80vw", height: "90%" }}
      className=" flex items-start justify-start overflow-auto"
    >
      <div
        className=" flex flex-col items-start justify-start p-5 h-max w-full align-content-start "
        style={{ overflowY: "auto" }}
      >
        <div className="flex items-center justify-between gap-6 pt-1 flex-wrap w-full">
          <h1 className="text-2xl font-semibold antialiased uppercase">
            {item.name}
          </h1>
          <div className="flex items-start gap-6">
            {item.content_id && !data?.related_sections && (
              <CustomButton
                label="Delete content"
                onClick={() => setShowDeleteConfirmationModal(true)}
              />
            )}
            <CustomButton
              label="Save Changes"
              disabled={
                editedContent === data?.to_approve ||
                editedContent?.length === 0 ||
                !editedContent
              }
              onClick={() => sendContent()}
            />
            <CustomButton
              label="Discart Changes"
              disabled={editedContent === data?.to_approve}
              onClick={() => {
                setEditedContent(data?.to_approve);
              }}
            />
          </div>
        </div>
        <div className="mt-5" />
        <div className="mt-5" />
        <Modal show={showConfirmationModal} onClose={handleCloseModal}>
          <ConfirmationModal
            onClose={handleCloseModal}
            type="important"
            confirmButtonText="I understand"
            message="You're going to detach this section making it have an unique content that is not shared. This operation is irreversible!"
            callback={(v) => {
              if (v) {
                sendDetachAction();
              }
            }}
          />
        </Modal>
        <Modal show={showDeleteConfirmationModal} onClose={handleCloseModal}>
          <ConfirmationModal
            onClose={handleCloseModal}
            type="important"
            confirmButtonText="I understand"
            message="You're going to delete this content. This operation is irreversible!"
            callback={(v) => {
              if (v) {
                sendDeleteContent();
              }
            }}
          />
        </Modal>
        {data?.related_sections && (
          <>
            <div
              className="w-full bg-black mt-10 pl-2"
              style={{ cursor: "pointer" }}
            >
              <label
                className="text-xl text-white font-semibold antialiased mt-10 "
                style={{ cursor: "pointer" }}
              >
                WARNING: This section was created using the `Create Content for
                multiple Children` tool, changing it will change all others.
                Click to see affected sections.
              </label>
            </div>
            <div>
              <ul className="flex flex-col justify-start items-start p-2 rounded-lg list-disc mr-4 ">
                {data.related_sections.map((rs, index) => {
                  return rs.map((v) => {
                    return v.Key === "name" && <li> {v.Value}</li>;
                  });
                })}
              </ul>
              <CustomButton
                label="Detach section"
                onClick={() => setShowConfirmationModal(true)}
                tailwindColor="bg-red-700"
              />
            </div>
          </>
        )}
        <div className="mt-5 mb-2 flex flex-row">
          <label className="text-lg font-semibold antialiased self-center mr-5">
            Content being edited
          </label>
          <select
            disabled={currentStatus === ContentStatusDict["Approved"]}
            value={currentStatus}
            onChange={(e) => {
              changeContentStatus(e.target.value);
            }}
            className="h-10 w-32 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 disabled:bg-gray-600"
          >
            {getStatusEnum()}
          </select>
          <Select
            options={selectOptions}
            className="h-10 w-48 border border-gray-300 text-sm rounded-lg pl-5"
            onChange={(e: any) => {
              let newContent = editedContent;
              newContent += ` [${e?.name}](chart:${e?.value})`;
              setEditedContent(newContent);
            }}
          />
        </div>
        <MDEditor
          height={400}
          style={{ width: "100%", height: 2000, backgroundColor: "#e5e5e5" }}
          value={editedContent}
          onChange={(v) => setEditedContent(v)}
        />
        {(data?.status === ContentStatusDict["Ready for approval"] ||
          data?.content === editedContent) && (
          <CustomButton
            label="Approve Changes"
            onClick={() => sendContentApproval()}
          />
        )}
        {data !== undefined && (
          <label className="text-lg font-semibold antialiased mt-5 ">
            Approved content
          </label>
        )}
        {data !== undefined && (
          <MDEditor
            height={400}
            style={{ width: "100%", height: 2000, backgroundColor: "#e5e5e5" }}
            value={data.content}
            preview="preview"
            hideToolbar
          />
        )}
      </div>
    </section>
  );
};

export { ViewContentModal };
