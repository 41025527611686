import React from "react";
import { CONTENT_PAGE_STATE_TYPE } from "..";
import { CustomButton } from "../../../styles/custom_button";

interface ActionItemProps {
  setPageState: React.Dispatch<React.SetStateAction<CONTENT_PAGE_STATE_TYPE>>;
}
const ActionBar: React.FunctionComponent<ActionItemProps> = ({
  setPageState,
}) => {
  return (
    <section className="w-fill flex flex-row items-center justify-start p-4 rounded-md drop-shadow-lg backdrop-blur-md bg-opacity-60">
      <div className="flex flex-1 ">
        <span className="font-semibold text-lg bg-black pr-5 pl-5 rounded-md text-white">
          {/* {item.name} */}
          Actions
        </span>
      </div>

      <div className="flex flex-row items-between justify-between gap-6">
        {/* <CustomButton label="Create a content" onClick={() => {}} /> */}
        <CustomButton
          label="Content for multiple sections"
          onClick={() => setPageState("multiple-content__step-1")}
        />
      </div>
    </section>
  );
};

export { ActionBar };
