import { KanbanData, TKanbanCard } from "../../../../utils/types/kanban";

export function buildDataObject(
  rssNews: TKanbanCard[],
  columnsData: { [columnId: string]: TKanbanCard[] | null },
  filterTerm: string,
  isAscending: boolean = true
): KanbanData {
  const filterCards = (cards: TKanbanCard[]) =>
    cards.filter((card) =>
      card.title.toLowerCase().includes(filterTerm.toLowerCase())
    );

  const sortedRssNewsByPublishedAt = [...rssNews].sort((a, b) => {
    const dateA = a.publishedAt ? new Date(a.publishedAt) : null;
    const dateB = b.publishedAt ? new Date(b.publishedAt) : null;

    if (dateA === null && dateB === null) return 0;
    if (dateA === null) return 1;
    if (dateB === null) return -1;

    return isAscending
      ? dateA.getTime() - dateB.getTime()
      : dateB.getTime() - dateA.getTime();
  });

  const filteredRssNews = filterCards(sortedRssNewsByPublishedAt);

  const filteredColumnsData = Object.keys(columnsData).reduce((acc, key) => {
    const sortedCards = (columnsData[key] || []).sort((a, b) => {
      const dateA = a.notice_date ? new Date(a.notice_date) : null;
      const dateB = b.notice_date ? new Date(b.notice_date) : null;

      if (dateA === null && dateB === null) return 0;
      if (dateA === null) return 1;
      if (dateB === null) return -1;

      return isAscending
        ? dateA.getTime() - dateB.getTime()
        : dateB.getTime() - dateA.getTime();
    });

    acc[key] = sortedCards ? filterCards(sortedCards) : null;
    return acc;
  }, {} as typeof columnsData);

  const cards = {
    ...filteredRssNews.reduce((acc, card, index) => {
      acc[`card-${index + 1}`] = card;
      return acc;
    }, {} as any),
    ...(filteredColumnsData["column-2"]
      ? filteredColumnsData["column-2"].reduce((acc, card, index) => {
          acc[`card-${filteredRssNews.length + index + 1}`] = card;
          return acc;
        }, {} as any)
      : {}),
    ...(filteredColumnsData["column-3"]
      ? filteredColumnsData["column-3"].reduce((acc, card, index) => {
          acc[
            `card-${
              filteredRssNews.length +
              (filteredColumnsData["column-2"]?.length || 0) +
              index +
              1
            }`
          ] = card;
          return acc;
        }, {} as any)
      : {}),
    ...(filteredColumnsData["column-4"]
      ? filteredColumnsData["column-4"]
          .filter(Boolean)
          .reduce((acc, card, index) => {
            acc[
              `card-${
                filteredRssNews.length +
                (filteredColumnsData["column-2"]?.length || 0) +
                (filteredColumnsData["column-3"]?.length || 0) +
                index +
                1
              }`
            ] = card;
            return acc;
          }, {} as any)
      : {}),
  };

  const columns = {
    "column-1": {
      id: "column-1",
      title: "News",
      cardIds: filteredRssNews.map((_, index) => `card-${index + 1}`),
    },
    "column-2": {
      id: "column-2",
      title: "Interesting",
      cardIds:
        filteredColumnsData["column-2"]?.map(
          (_, index) => `card-${filteredRssNews.length + index + 1}`
        ) || [],
    },
    "column-3": {
      id: "column-3",
      title: "Under Evaluation",
      cardIds:
        filteredColumnsData["column-3"]?.map(
          (_, index) =>
            `card-${
              filteredRssNews.length +
              (filteredColumnsData["column-2"]?.length || 0) +
              index +
              1
            }`
        ) || [],
    },
    "column-4": {
      id: "column-4",
      title: "Curated",
      cardIds:
        filteredColumnsData["column-4"]?.map(
          (_, index) =>
            `card-${
              filteredRssNews.length +
              (filteredColumnsData["column-2"]?.length || 0) +
              (filteredColumnsData["column-3"]?.length || 0) +
              index +
              1
            }`
        ) || [],
    },
  };

  const columnOrder = ["column-1", "column-2", "column-3", "column-4"];

  return {
    columns,
    cards,
    columnOrder,
  };
}
