import React, { useState } from "react";
import { BasePage } from "../base";
import { Container } from "./style";
import ReactLoading from "react-loading";
import { UserItem } from "./components/user_item";
import { useGetUsers } from "../../../infra/api";
import { User } from "../../shared/types/user";
import { CustomButton } from "../../styles/custom_button";
import { ViewUserModal } from "./components/view_user_modal";
import { Modal } from "../../components";

const UsersPage: React.FunctionComponent = () => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const { isLoading: userIsLoading, data, isRefetching } = useGetUsers();
  let isLoading = userIsLoading || isRefetching;

  const handleCloseModal = React.useCallback(() => {
    setShowCreateUserModal(false);
    setCurrentUser(null);
  }, []);

  const onChangeStructure = React.useCallback(() => {
    handleCloseModal();
  }, [handleCloseModal]);

  const handleOpenCreateUserModal = () => {
    setCurrentUser(null);
    setShowCreateUserModal(true);
  };

  return (
    <BasePage>
      <Container className="dv-structure">
        <>
          <Modal show={showCreateUserModal} onClose={handleCloseModal}>
            <ViewUserModal
              onClose={handleCloseModal}
              item={currentUser}
              onChangeStructure={onChangeStructure}
            />
          </Modal>
          <div className="pt-16 flex-row flex items-center justify-between mb-6 ">
            <h1 className="text-2xl font-semibold antialiased uppercase pr-2 self-center">
              User Manager
            </h1>
            <div className="flex flex-row items-end justify-end h-min gap-6 ">
              <CustomButton
                label="Create user"
                onClick={() => handleOpenCreateUserModal()}
              />
            </div>
          </div>
          {isLoading || !data ? (
            <section
              className="w-fill  flex align-center justify-center "
              style={{
                height: 500,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ReactLoading
                type="cylon"
                color="#000000"
                height={"10%"}
                width={"10%"}
              />
            </section>
          ) : (
            <section className="flex">
              <div className="flex-1 p-4">
                {data.length === 0 && (
                  <p
                    className={"text-black"}
                    style={{ marginLeft: "4px", fontSize: "1.2em" }}
                  >
                    {"No content users created"}
                  </p>
                )}
                {data &&
                  data.map((user) => (
                    <UserItem
                      user={user}
                      setCurrentUser={setCurrentUser}
                      currentUser={currentUser ?? null}
                      key={user.id}
                    />
                  ))}
              </div>
            </section>
          )}{" "}
        </>
      </Container>
    </BasePage>
  );
};

export { UsersPage };
