import React from "react";
import { CustomButton } from "../../styles/custom_button";

interface ConfirmationModalProps {
  callback: (value: boolean) => void;
  message: string;
  confirmButtonText: string;
  type: "normal" | "warning" | "important";
  onClose: () => void;
}

const ConfirmationModal: React.FunctionComponent<ConfirmationModalProps> = ({
  callback,
  message,
  confirmButtonText,
  type,
  onClose,
}) => {
  function getBgColor(): string {
    switch (type) {
      case "normal":
        return "";
      case "warning":
        return "bg-yellow-600";
      case "important":
        return "bg-red-300 p-2 border rounded-md ";

      default:
        return "";
    }
  }
  return (
    <section className="flex flex-col items-center justify-center pt-5 pr-8 pl-8 ">
      <h1 className="text-black antialising text-xl font-semibold mb-8">
        Are you sure?
      </h1>
      <div className={getBgColor()}>
        <span className="text-black antialising text-normal font-semibold mb-8">
          {message}
        </span>
      </div>
      <div className="flex items-center justify-center gap-x-6 mt-10">
        <CustomButton
          label={confirmButtonText}
          onClick={() => callback(true)}
        />
        <CustomButton
          tailwindColor="bg-gray-300"
          textTailwindColor="text-black"
          label="Cancel"
          onClick={() => {
            callback(false);
            onClose();
          }}
        />
      </div>
    </section>
  );
};

export { ConfirmationModal };
