import React from "react";
import { Footer } from "../../components/footer";
import Header from "../../components/header";
import { Container, PageContainer } from "./styles";

interface BasePageProps {
  children?: React.ReactNode;
}

const BasePage: React.FunctionComponent<BasePageProps> = ({ children }) => {
  return (
    <Container>
      <Header />
      <PageContainer>{children}</PageContainer>
      <Footer
        style={{
          width: "100%",
          boxSizing: "border-box",
          overflowX: "hidden",
        }}
      />
    </Container>
  );
};
export { BasePage };
