import React, { useState, useEffect } from "react";
import { NavItem } from "../../../../shared/types";
import { CustomButton } from "../../../../styles/custom_button";

interface ActionItemProps {
  item: NavItem;
  onClickViewSection: () => void;
  onChangeStructure: () => void;
  onClickDelete: () => void;
  onClickContent: () => void;
  onClickCreateBaseNode: () => void;
}

const ActionItem: React.FunctionComponent<ActionItemProps> = ({
  item,
  onClickViewSection,
  onClickDelete,
  onClickContent,
  onClickCreateBaseNode,
}) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const updateScrollPosition = () => {
      setScrollPosition(window.pageYOffset);
    };

    window.addEventListener("scroll", updateScrollPosition);

    return () => window.removeEventListener("scroll", updateScrollPosition);
  }, []);

  return (
    <section
      className=" flex flex-col items-center justify-start p-4 rounded-md bg-gray-100  mr-10 gap-6 relative h-min"
      style={{ top: `${scrollPosition}px` }}
    >
      <span className="flex items-center justify-center font-semibold text-lg bg-white px-5 h-12 rounded-md ">
        {item.name}
      </span>

      <div className="mt-4" />
      <CustomButton label="View Section" onClick={onClickViewSection} />

      {item.content_id ? (
        <CustomButton label="View Content" onClick={onClickContent} />
      ) : (
        <CustomButton label="Create Content" onClick={onClickContent} />
      )}

      <CustomButton label="Create Base Node" onClick={onClickCreateBaseNode} />

      <CustomButton label="Delete Section" onClick={onClickDelete} />
    </section>
  );
};

export { ActionItem };
