import React from "react";
import Logo from "../../assets/dlt-logo.svg";
interface FooterProps {
  style?: React.CSSProperties;
}
const Footer: React.FunctionComponent<FooterProps> = ({ style }) => {
  return (
    <footer style={style} className="bg-white shadow dark:bg-black">
      <div className="w-full container mx-auto p-2 md:px-2 md:py-2">
        <div className="sm:flex sm:items-center sm:justify-center my-4">
          <a
            href="https://dlt360.io/"
            className="flex items-center justify-center mb-0 sm:mb-0"
          >
            <img src={Logo} className="h-8 mr-3" alt="DLT 360" />
            <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
              DLT 360
            </span>
          </a>
        </div>
        {/* <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" /> */}
        <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">
          ©
          <a href="https://dlt360.io/" className="hover:underline">
            DLT 360™
          </a>
          . All Rights Reserved.
        </span>
      </div>
    </footer>
  );
};

export { Footer };
