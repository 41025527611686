import React from "react";
import { Droppable } from "react-beautiful-dnd";
import { NewsItem } from "../../../utils/types/news-radar";
import { TKanbanCard, TKanbanColumn } from "../../../utils/utils";
import KanbanCard from "./kanban_card";

type ColumnProps = {
  column: TKanbanColumn;
  cards: TKanbanCard[];
  onCardClick: (card: TKanbanCard) => void;
  onSendClick: (news: NewsItem, card: TKanbanCard, from_column: string) => void;
};

const KanbanColumn = ({
  column,
  cards,
  onCardClick,
  onSendClick,
}: ColumnProps) => {
  return (
    <Droppable droppableId={column.id}>
      {(provided, snapshot) => (
        <div
          className="bg-gray-700 mx-4 mb-8 pb-8 px-4 rounded w-full h-full overflow-y-auto overflow-x-hidden custom-scrollbar "
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          <h3 className="text-2xl text-white  antialiased mt-2 uppercase mb-1">
            {column.title}
          </h3>
          <div className="h-0.5 w-full bg-gray-500 mb-4" />
          {cards.map((card, index) => (
            <KanbanCard
              key={card.id}
              card={card}
              index={index}
              onCardClick={onCardClick}
              column_id={column.id}
              onSendClick={onSendClick}
            />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default KanbanColumn;
