import { LimitedNavigatorChart } from "../../shared/types";

export const recursivelyIterateThroughChart = (
  sectionId: string,
  data: Partial<LimitedNavigatorChart>
): Partial<LimitedNavigatorChart> | null => {
  if (data.id === sectionId) {
    return data;
  }
  if (data.children) {
    for (let i = 0; i < data.children.length; i++) {
      const child = data.children[i];
      const result = recursivelyIterateThroughChart(sectionId, child);
      if (result) {
        return result;
      }
    }
  }
  return null;
};

export const recursivelyIterateThroughChartToFindParent = (
  sectionClickedFullString: string,
  data: Partial<LimitedNavigatorChart>
): Partial<LimitedNavigatorChart> | null => {
  if (sectionClickedFullString === "Navigator") {
    return data;
  }
  if (sectionClickedFullString === data.id) {
    return data;
  }
  if (data.children) {
    for (let i = 0; i < data.children.length; i++) {
      const child = data.children[i];
      if (child.id === sectionClickedFullString) {
        return data;
      }
      const result: any = recursivelyIterateThroughChartToFindParent(
        sectionClickedFullString,
        child
      );
      if (result) {
        return result;
      }
    }
  }
  return null;
};
