import React from "react";
import ReactLoading from "react-loading";
import { useGetContent } from "../../../../infra/api";
import { ModalContent } from "./components/content";
import * as S from "./styles";

interface SunburstModalProps {
  contentId: string;
  title: string;
}

const SunburstModal: React.FunctionComponent<SunburstModalProps> = ({
  contentId,
  title,
}) => {
  const { isLoading: contentIsLoading, data } = useGetContent(contentId);

  function renderContent() {
    return data ? (
      <ModalContent content={data!} title={title} />
    ) : (
      <p>
        Coming soon - No content in category. Sorry. We could not find any
        information for this section. You can proceed to the next one or go
        back.
      </p>
    );
  }
  return (
    <S.Container>
      {contentIsLoading ? <ReactLoading /> : renderContent()}
    </S.Container>
  );
};

export { SunburstModal };
