import React from "react";

interface PinkButtonProps {
  onClick: () => void;
  label: string;
  tailwindColor?: string;
  textTailwindColor?: string;
  disabled?: boolean;
  title?: string;
  leading?: JSX.Element;
  trailing?: JSX.Element;
  rotate?: boolean;
}
const CustomButton: React.FunctionComponent<PinkButtonProps> = ({
  label,
  onClick,
  tailwindColor,
  textTailwindColor,
  disabled = false,
  title,
  leading,
  trailing,
  rotate,
}) => {
  var buttoncStyleClass =
    "flex items-center justify-center  rounded-md bg-black px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black w-48 text-center";
  if (tailwindColor) {
    buttoncStyleClass = `flex items-center justify-center  rounded-md ${tailwindColor} px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black w-48 text-center`;
  }
  if (tailwindColor && textTailwindColor) {
    buttoncStyleClass = `flex items-center justify-center  rounded-md ${tailwindColor} px-3.5 py-2.5 text-sm font-semibold ${textTailwindColor} shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black w-48 text-center`;
  }
  if (disabled) {
    buttoncStyleClass =
      "flex items-center justify-center  rounded-md bg-gray-400 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm w-48";
  }
  if (rotate) {
    buttoncStyleClass += " rotate90 ";
  }
  return (
    <div
      title={title}
      className={"flex items-center justify-center gap-x-6 w-max"}
      onClick={!disabled ? onClick : undefined}
    >
      <span
        style={{ cursor: disabled ? "default" : "pointer" }}
        className={buttoncStyleClass}
      >
        {leading}
        {label}
        {trailing}
      </span>
    </div>
  );
};

export { CustomButton };
