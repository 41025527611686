import React from "react";
import { useGetChart } from "../../../infra/api";
import { BasePage } from "../base";
import { ContentChart } from "./components/charts/content_chart";
import { SectionsChart } from "./components/charts/sections_chart";

import { Container } from "./style";

const Home: React.FunctionComponent = () => {
  const { data } = useGetChart("0");
  return (
    <BasePage>
      <Container className="dv-home">
        <h2 className="text-xl font-bold antialising mb-10">Dashboard</h2>

        <section className="flex flex-col gap-20">
          <SectionsChart structure={data} />
          <ContentChart structure={data} />
        </section>
      </Container>
    </BasePage>
  );
};

export { Home };
