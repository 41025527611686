import React, { useState } from "react";
import { BasePage } from "../base";
import ReactLoading from "react-loading";
import {
  useCreateChartKind,
  useDeleteChartKind,
  useGetChartKindsList,
} from "../../../infra/api";
import { CustomButton } from "../../styles/custom_button";
import { NavigatorChartKindInfo } from "../../shared/types";
import { formatDateTime } from "../../../utils/formatter";
import { FaCheck, FaTimes } from "react-icons/fa";
import toast from "react-hot-toast";

const ChartKindsPage: React.FunctionComponent = () => {
  const [showCreateInput, setShowCreateInput] = useState<boolean>(false);
  const [createText, setCreateText] = useState<string>("");
  const {
    isLoading: kindsLoading,
    data: kinds,
    isRefetching,
  } = useGetChartKindsList();
  const { mutateAsync: createKind, isLoading: creationIsLoading } =
    useCreateChartKind();

  let isLoading = kindsLoading || isRefetching || creationIsLoading;
  const sendCreateKind = React.useCallback(async () => {
    createKind(createText).then(() => {
      toast.success("Type created  with success", {
        duration: 1000,
      });
    });
  }, [createKind, createText]);
  return (
    <BasePage>
      <div>
        <div className="pt-16 flex-row flex items-center justify-between mb-6 ">
          <h1 className="text-2xl font-semibold antialiased uppercase pr-2 self-center">
            Chart Types
          </h1>
          <div className="flex gap-12 items-center justify-center">
            <CustomButton
              label={showCreateInput ? "Stop Creating" : "Create Type"}
              onClick={() => setShowCreateInput((state) => !state)}
            />
            {showCreateInput && (
              <div className="flex items-center justify-center gap-2">
                <input
                  type="text"
                  className="border rounded-md py-2 px-3 w-300"
                  value={createText}
                  onChange={(e) => setCreateText(e.target.value)}
                  placeholder="ex: Digital Innovation"
                />
                <div
                  className={`bg-blue-500 p-3 cursor-pointer rounded-md hover:bg-blue-200`}
                  onClick={() => {
                    if (createText === "") {
                      toast.error(
                        "You should write something to create a type"
                      );
                      return;
                    }
                    sendCreateKind();
                  }}
                >
                  <FaCheck className="text-white" />
                </div>
              </div>
            )}
          </div>
        </div>
        {isLoading || !kinds ? (
          <section
            className="w-fill  flex align-center justify-center "
            style={{
              height: 500,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ReactLoading
              type="cylon"
              color="#000000"
              height={"10%"}
              width={"10%"}
            />
          </section>
        ) : (
          <section className="flex flex-col items-start justify-center ">
            {kinds.map((k) => {
              return <KindItem kind={k} />;
            })}
          </section>
        )}
      </div>
    </BasePage>
  );
};

function KindItem({ kind }: { kind: NavigatorChartKindInfo }) {
  const [isHovered, setIsHovered] = useState(false);

  // Get the mutate function from useMutation
  const mutation = useDeleteChartKind();

  return (
    <div
      className="flex items-center justify-between p-4 my-4 rounded-xl bg-gray-200 w-full hover:bg-gray-300 relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <span className="flex font-xl font-semibold antialising">
        {kind.kind}
      </span>
      {isHovered && (
        <div className="absolute bottom-14 mt-12 w-64 h-18 p-2 text-black rounded shadow-lg text-white bg-black">
          Created at {formatDateTime(kind.created_at.toString())}
        </div>
      )}

      {isHovered && (
        <div
          className="flex cursor-pointer p-4"
          onClick={() => {
            // Use the mutate function here
            mutation.mutate(kind.kind);
          }}
        >
          <FaTimes
            className="text-black text-red-500"
            title="Delete"
            size={25}
          />
        </div>
      )}
    </div>
  );
}

export { ChartKindsPage };
