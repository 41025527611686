import styled from "styled-components";

const HEADER_HEIGHT = "120px";
const FOOTER_HEIGHT = "100px";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PageContainer = styled.div`
  flex: 1;
  height: 100%;
  margin: 0 16px;
  margin-top: ${HEADER_HEIGHT};
  margin-bottom: ${FOOTER_HEIGHT};
  min-height: calc(100vh - ${HEADER_HEIGHT} - ${FOOTER_HEIGHT});
  display: flex;
  flex-direction: column;
`;
