import { useMutation, useQuery, useQueryClient } from "react-query";
import { API_BASE_URL } from "../app/utilts/utils";
import {
  ChangeStatusInterface,
  ContentStatusInfo,
  CreateMultipleContentRequest,
  MoveItemRequest,
  NavItem,
  NavItemRequest,
  NavigatorChart,
  NavigatorChartVersionInfo,
  NavigatorContent,
  NavigatorChartKindInfo,
} from "../app/shared/types";
import axios from "axios";
import { User, UserRequest } from "../app/shared/types/user";
import { useChart } from "../contexts/chart_context";
import { NewsTag, NewsCategory, NewsItem } from "../utils/types/news-radar";
import { TKanbanCard } from "../utils/utils";

const api = axios.create({
  baseURL: `${API_BASE_URL}`,
});

export function useGetChart(selectedVersionId: string) {
  const { kind } = useChart();
  var route: string =
    selectedVersionId === "0"
      ? `/v2/vchart/current`
      : `/v2/vchart/${selectedVersionId}`;
  if (kind !== "main") {
    route =
      selectedVersionId === "0"
        ? `/v2/vchart/current?kind=${kind}`
        : `/v2/vchart/${selectedVersionId}?kind=${kind}`;
  }
  return useQuery<NavigatorChart>(
    ["chart", selectedVersionId, kind],
    async () => await api.get(route).then((res) => res.data),
    { refetchOnWindowFocus: false }
  );
}

export function useGetChartVersions() {
  return useQuery<NavigatorChartVersionInfo[]>(
    "chartVersions",
    async () => await api.get(`/v2/vchart/list`).then((res) => res.data),
    { refetchOnWindowFocus: false }
  );
}

export function useDeleteChartNode() {
  const queryClient = useQueryClient();
  const { kind } = useChart();
  return useMutation(
    async (nodeId: string) =>
      await api.delete(
        `/v2/chart/${nodeId}${kind !== "main" ? `?kind=${kind}` : ""}`
      ),
    {
      onSuccess: () => {
        // Invalidate the query so that it will be refetched
        queryClient.invalidateQueries("chart");
      },
    }
  );
}

export function useMoveChartNode() {
  const queryClient = useQueryClient();
  const { kind } = useChart();
  return useMutation(
    async (body: MoveItemRequest) =>
      await api.post(
        `/v2/chart/move${kind !== "main" ? `?kind=${kind}` : ""}`,
        body
      ),
    {
      onSuccess: () => {
        // Invalidate the query so that it will be refetched
        queryClient.invalidateQueries("chart");
      },
    }
  );
}

export function useCreateChartNode() {
  const queryClient = useQueryClient();
  const { kind } = useChart();
  return useMutation(
    async (node: NavItemRequest) =>
      await api.post(
        `/v2/chart/${node.parent_id}${kind !== "main" ? `?kind=${kind}` : ""}`,
        node
      ),
    {
      onSuccess: () => {
        // Invalidate the query so that it will be refetched
        queryClient.invalidateQueries("chart");
      },
    }
  );
}
export function useCreateBaseChartNode() {
  const queryClient = useQueryClient();
  const { kind } = useChart();
  return useMutation(
    async (node: any) =>
      await api.post(
        `/v2/chart/base${kind !== "main" ? `?kind=${kind}` : ""}`,
        node
      ),
    {
      onSuccess: () => {
        // Invalidate the query so that it will be refetched
        queryClient.invalidateQueries("chart");
      },
    }
  );
}

export function useUpdateChartNode() {
  const queryClient = useQueryClient();
  const { kind } = useChart();

  return useMutation(
    async (node: NavItem) =>
      await api.put(
        `/v2/chart/${node.id}${kind !== "main" ? `?kind=${kind}` : ""}`,
        node
      ),
    {
      onSuccess: () => {
        // Invalidate the query so that it will be refetched
        queryClient.invalidateQueries("chart");
      },
    }
  );
}

export function useGetContent(contentId?: string) {
  return useQuery<NavigatorContent>(
    ["selectedNodeContent", contentId],
    async () =>
      await api.get(`/v2/content/${contentId}`).then((res) => res.data),
    {
      refetchOnWindowFocus: false,
      enabled: contentId !== undefined && contentId.length > 0,
    }
  );
}

export function useCreateContent() {
  const queryClient = useQueryClient();
  const { kind } = useChart();

  var route: string = "/v2/content";
  if (kind !== "main") {
    route = `/v2/content?kind=${kind}`;
  }
  return useMutation(
    async (contentRequest: Partial<NavigatorContent>) =>
      await api.post(route, contentRequest),
    {
      onSuccess: () => {
        // Invalidate the query so that it will be refetched
        queryClient.invalidateQueries("selectedNodeContent");
        queryClient.invalidateQueries("chart");
        queryClient.invalidateQueries("contentsStatus");
      },
    }
  );
}

export function useDeleteContent() {
  const queryClient = useQueryClient();
  const { kind } = useChart();

  return useMutation(
    async (contentId: string) =>
      await api.delete(
        `/v2/content/${contentId}${kind !== "main" ? `?kind=${kind}` : ""}`
      ),
    {
      onSuccess: () => {
        // Invalidate the query so that it will be refetched
        queryClient.invalidateQueries("selectedNodeContent");
        queryClient.invalidateQueries("chart");
        queryClient.invalidateQueries("contentsStatus");
      },
    }
  );
}

export function useUpdateContent() {
  const queryClient = useQueryClient();
  return useMutation(
    async (contentRequest: Partial<NavigatorContent>) =>
      await api.put(`/v2/content`, contentRequest),
    {
      onSuccess: () => {
        // Invalidate the query so that it will be refetched
        queryClient.invalidateQueries("selectedNodeContent");
        queryClient.invalidateQueries("chart");
      },
    }
  );
}

export function useApproveContent() {
  const queryClient = useQueryClient();
  return useMutation(
    async (contentId: string) =>
      await api.patch(`/v2/content/approve/${contentId}`),
    {
      onSuccess: () => {
        // Invalidate the query so that it will be refetched
        queryClient.invalidateQueries("selectedNodeContent");
        queryClient.invalidateQueries("chart");
        queryClient.invalidateQueries("contentsStatus");
      },
    }
  );
}

export function useContentStatusChange() {
  const queryClient = useQueryClient();
  return useMutation(
    async (request: ChangeStatusInterface) =>
      await api.patch(`/v2/content/status/${request.contentId}`, request.body),
    {
      onSuccess: () => {
        // Invalidate the query so that it will be refetched
        queryClient.invalidateQueries("selectedNodeContent");
        queryClient.invalidateQueries("chart");
        queryClient.invalidateQueries("contentsStatus");
      },
    }
  );
}

export function useGetContentStatus() {
  const { kind } = useChart();
  var route: string = "/v2/vchart/content";
  if (kind !== "main") {
    route = `/v2/vchart/content?kind=${kind}`;
  }
  return useQuery<ContentStatusInfo[]>(
    "contentsStatus",
    async () => await api.get(route).then((res) => res.data),
    { refetchOnWindowFocus: false }
  );
}

export function useCreateChartVersion() {
  const queryClient = useQueryClient();
  return useMutation(async () => await api.post(`/v2/vchart`), {
    onSuccess: () => {
      // Invalidate the query so that it will be refetched
      queryClient.invalidateQueries("selectedNodeContent");
      queryClient.invalidateQueries("chart");
      queryClient.invalidateQueries("contentsStatus");
      queryClient.invalidateQueries("chartVersions");
    },
  });
}

export function useCreateContentMultiple() {
  const queryClient = useQueryClient();
  return useMutation(
    async (contentRequest: Partial<CreateMultipleContentRequest>) =>
      await api.post(`/v2/content/multiple`, contentRequest),
    {
      onSuccess: () => {
        // Invalidate the query so that it will be refetched
        queryClient.invalidateQueries("selectedNodeContent");
        queryClient.invalidateQueries("chart");
        queryClient.invalidateQueries("contentsStatus");
      },
    }
  );
}

export function useDetachSectionContent() {
  const queryClient = useQueryClient();
  return useMutation(
    async (section_id: string) =>
      await api.patch(`/v2/content/detach/${section_id}`),
    {
      onSuccess: () => {
        // Invalidate the query so that it will be refetched
        queryClient.invalidateQueries("selectedNodeContent");
        queryClient.invalidateQueries("chart");
        queryClient.invalidateQueries("contentsStatus");
      },
    }
  );
}

export function useGetUsers() {
  return useQuery<User[]>(
    ["users"],
    async () => await api.get("/users").then((res) => res.data),
    { refetchOnWindowFocus: false }
  );
}

export function useCreateUser() {
  const queryClient = useQueryClient();
  return useMutation(
    async (node: UserRequest) => await api.post(`/users`, node),
    {
      onSuccess: () => {
        // Invalidate the query so that it will be refetched
        queryClient.invalidateQueries("users");
      },
    }
  );
}

export function useDeleteUser() {
  const queryClient = useQueryClient();
  return useMutation(
    async (userId: string) => await api.delete(`/users/${userId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
      },
    }
  );
}
export function useGetChartKindsList() {
  return useQuery<NavigatorChartKindInfo[]>(
    "chartKinds",
    async () => await api.get(`/v2/vchart/kinds`).then((res) => res.data),
    { refetchOnWindowFocus: false }
  );
}

export function useDeleteChartKind() {
  const queryClient = useQueryClient();
  return useMutation(
    async (kind: string) => await api.delete(`/v2/vchart/${kind}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("chartKinds");
      },
    }
  );
}
export function useCreateChartKind() {
  const queryClient = useQueryClient();
  return useMutation(
    async (kind: string) => await api.post(`/v2/vchart/new/${kind}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("chartKinds");
      },
    }
  );
}

export function useGetNewsTags(contentId?: string) {
  return useQuery<NewsTag[]>(
    ["getNewsTags"],
    async () => await api.get("news/tags").then((res) => res.data),
    {
      refetchOnWindowFocus: false,
    }
  );
}

export function useCreateNewsTag() {
  const queryClient = useQueryClient();
  return useMutation(
    async (tag: { title: string }) => await api.post(`/news/tags`, tag),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getNewsTags");
      },
    }
  );
}
export function useUpdateNewsTag() {
  const queryClient = useQueryClient();
  return useMutation(
    async (tag: NewsTag) =>
      await api.put(`/news/tags/${tag.id}`, { title: tag.title }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getNewsTags");
      },
    }
  );
}
export function useGetNewsCategories() {
  return useQuery<NewsCategory[]>(
    ["getNewsCategories"],
    async () => await api.get("news/categories").then((res) => res.data),
    {
      refetchOnWindowFocus: false,
    }
  );
}
export function useCreateNewsCategory() {
  const queryClient = useQueryClient();
  return useMutation(
    async (category: Partial<NewsCategory>) =>
      await api.post(`/news/category`, category),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getNewsCategories");
      },
    }
  );
}
export function useUpdateNewsCategory() {
  const queryClient = useQueryClient();
  return useMutation(
    async (category: Partial<NewsCategory>) =>
      await api.put(`/news/category/${category.id}`, category),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getNewsCategories");
      },
    }
  );
}

export function useGetNewsById(news_id: string) {
  return useQuery<NewsItem>(
    ["getNewsById", news_id],
    () => api.get(`news/${news_id}`).then((res) => res.data.data),
    {
      enabled: !!news_id,
      refetchOnWindowFocus: false,
    }
  );
}

export function useGetNewsRadarData() {
  return useQuery<NewsItem[]>(
    ["getNewsRadarData"],
    async () => await api.get("news/all").then((res) => res.data),
    {
      refetchOnWindowFocus: false,
    }
  );
}

export function useCreateNews() {
  const queryClient = useQueryClient();
  return useMutation(
    async (news: Partial<NewsItem>) => await api.post(`/news`, news),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getNewsRadarData");
      },
    }
  );
}

export function useUpdateNews() {
  const queryClient = useQueryClient();
  return useMutation(
    async (news: Partial<NewsItem>) => await api.put(`/news/${news.id}`, news),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getNewsRadarData");
      },
    }
  );
}
export function useDeleteNews() {
  const queryClient = useQueryClient();
  return useMutation(
    async (newsId: string) => await api.delete(`/news/${newsId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getNewsRadarData");
      },
    }
  );
}

export function useGetKanbanRSSNews() {
  return useQuery<TKanbanCard[]>(
    ["getKanbanRSSNews"],
    async () =>
      await api.get<TKanbanCard[]>("kanban/rss").then((res) => res.data),
    {
      refetchOnWindowFocus: false,
    }
  );
}
export interface DragCardRequest {
  to_column: string;
  from_column: string;
  card_id: string;
}
export function useDragNewsCard() {
  return useMutation(
    async (request_data: DragCardRequest) =>
      await api.put(
        `/kanban/drag?to_column=${request_data.to_column}&from_column=${request_data.from_column}&card_id=${request_data.card_id}`
      ),
    {
      onSuccess: () => {},
    }
  );
}

export interface CreateCardRequest {
  rss_card_id: string;
  guid: string;
  title: string;
  description: string;
  link: string;
  publishedAt: string;
  content: string;
}
export function useCreateNewsCard() {
  const queryClient = useQueryClient();
  return useMutation(
    async (request_data: CreateCardRequest) =>
      await api.post(
        `/kanban/create/${request_data.rss_card_id}`,
        request_data
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getKanbanRSSNews"]);
      },
      onError: () => {
        queryClient.invalidateQueries(["getKanbanRSSNews"]);
      },
    }
  );
}
export interface DeleteKanbanCardRequest {
  from_column: string;
  card_id: string;
}
export function useDeleteKanbanCard() {
  return useMutation(
    async (request_data: DeleteKanbanCardRequest) =>
      await api.delete(
        `/kanban/delete/${request_data.card_id}?from_column=${request_data.from_column}`
      ),
    {
      onSuccess: () => {},
    }
  );
}
