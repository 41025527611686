import { Route, Router, Routes } from "react-router-dom";
import { createBrowserHistory, History } from "history";
import "./App.css";
import "./app/styles/global.css";
import { Home } from "./app/pages/home";
import { StructurePage } from "./app/pages/structure";
import { AuthProvider } from "./contexts/auth_provider";
import { ContentPage } from "./app/pages/content";
import { SigninPage } from "./app/pages/signin";
import { ProtectedRoute } from "./app/router/protected_route";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { RemoteConfigPage } from "./app/pages/remote_config";
import { QueryClient, QueryClientProvider } from "react-query";
import { UsersPage } from "./app/pages/users";
import { ChartProvider } from "./contexts/chart_context";
import { ChartKindsPage } from "./app/pages/chart_kinds";
import { NewsPage } from "./app/pages/news";
export const history: History = createBrowserHistory();

const firebaseConfig = {
  apiKey: "AIzaSyCfTkvUpmSeTi7F5DVVR_u_2WP-n36jg_A",
  authDomain: "dlt360-content-creation.firebaseapp.com",
  projectId: "dlt360-content-creation",
  storageBucket: "dlt360-content-creation.appspot.com",
  messagingSenderId: "1023932030564",
  appId: "1:1023932030564:web:90cf35e31cac880a7c0971",
  measurementId: "G-ZMYKDCGNGD",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

const queryClient = new QueryClient();

function App() {
  document.documentElement.setAttribute("data-color-mode", "light");

  return (
    <div className="App">
      <AuthProvider>
        <ChartProvider>
          <QueryClientProvider client={queryClient}>
            <Router navigator={history} location={history.location}>
              <Routes>
                <Route path="/signin" element={<SigninPage />}></Route>
                <Route
                  path="/"
                  element={
                    <ProtectedRoute>
                      <Home />
                    </ProtectedRoute>
                  }
                ></Route>
                <Route
                  path="/structure"
                  element={
                    <ProtectedRoute>
                      <StructurePage />
                    </ProtectedRoute>
                  }
                ></Route>
                <Route
                  path="/content"
                  element={
                    <ProtectedRoute>
                      <ContentPage />
                    </ProtectedRoute>
                  }
                ></Route>
                <Route
                  path="/remote_config"
                  element={
                    <ProtectedRoute>
                      <RemoteConfigPage />
                    </ProtectedRoute>
                  }
                ></Route>
                <Route
                  path="/users"
                  element={
                    <ProtectedRoute>
                      <UsersPage />
                    </ProtectedRoute>
                  }
                ></Route>
                <Route
                  path="/news"
                  element={
                    <ProtectedRoute>
                      <NewsPage />
                    </ProtectedRoute>
                  }
                ></Route>

                <Route
                  path="/chart-types"
                  element={
                    <ProtectedRoute>
                      <ChartKindsPage />
                    </ProtectedRoute>
                  }
                ></Route>
              </Routes>
            </Router>
          </QueryClientProvider>
        </ChartProvider>
      </AuthProvider>
    </div>
  );
}

export { App, analytics, auth };
