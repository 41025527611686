import React, { useState } from "react";
import { FaRegCaretSquareDown, FaRegCaretSquareRight } from "react-icons/fa";
import { User } from "../../../../shared/types/user";
import { CustomButton } from "../../../../styles/custom_button";
import { ConfirmationModal } from "../../../../components/modals/confirmation_modal";
import { useDeleteUser } from "../../../../../infra/api";
import toast from "react-hot-toast";
import { Modal } from "../../../../components";

interface UserProps {
  user: User;
  setCurrentUser: React.Dispatch<React.SetStateAction<User | null>>;
  currentUser: User | null;
}
const UserItem: React.FunctionComponent<UserProps> = ({
  user,
  setCurrentUser,
  currentUser,
}) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  const isEqual = currentUser?.id === user.id;
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { mutateAsync: deleteUser } = useDeleteUser();

  const handleCloseModal = React.useCallback(() => {
    setShowConfirmationModal(false);
    setCurrentUser(null);
  }, [setCurrentUser]);

  const sendDeleteUser = React.useCallback(async () => {
    handleCloseModal();
    deleteUser(user!.id)
      .then(() => {
        toast.success("Deleted with success", {
          duration: 1000,
        });
        setShowConfirmationModal(false);
      })
      .catch(() => {
        toast.error("Error - Something wrong happened", {
          duration: 1000,
        });
      });
  }, [deleteUser, handleCloseModal, user]);

  return (
    <div style={{ marginLeft: 20 }} key={user.id}>
      <Modal show={showConfirmationModal} onClose={handleCloseModal}>
        <ConfirmationModal
          onClose={handleCloseModal}
          type="important"
          confirmButtonText="I understand, delete it"
          message="You're going to delete this section. This operation is irreversible !"
          callback={(v) => {
            if (v) {
              sendDeleteUser();
            }
          }}
        />
      </Modal>
      <div
        className="w-96 flex items-center  justify-start  hover:text-gray-600 hover:border-red hover:bg-pink-100"
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        onClick={() => {
          setCurrentUser(user);
          return toggleExpand();
        }}
      >
        {user && (
          <>{expanded ? <FaRegCaretSquareDown /> : <FaRegCaretSquareRight />}</>
        )}
        <p
          className={isEqual ? "text-pink-600" : "text-black"}
          style={{ marginLeft: "4px", fontSize: "1.2em" }}
        >
          {user.email}
        </p>
      </div>
      {expanded && (
        <div className="w-96 bg-gray-200 rounded">
          <p
            className={"text-black"}
            style={{ marginLeft: "4px", fontSize: "1.2em" }}
          >
            {"Name: "} {user.first_name} {user.last_name}
          </p>
          <p
            className={"text-black"}
            style={{ marginLeft: "4px", fontSize: "1.2em" }}
          >
            {"Role: "} {user.role}
          </p>
          <CustomButton
            tailwindColor="bg-red-500"
            label="Delete"
            onClick={() => setShowConfirmationModal(true)}
          />
        </div>
      )}
    </div>
  );
};

export { UserItem };
