import styled from "styled-components";
import { modalBackground } from "../../../styles/app_colors";

export const Background = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(2px);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
`;
interface ContainerProps {
  width?: number;
  height?: number;
  padding?: number;
}
export const Container = styled.div<ContainerProps>`
  width: 35%;
  height: 80%;
  display: flex;
  flex-direction: column;

  padding-bottom: 24px;
  background-color: ${modalBackground};
  border-radius: 8px;
  opacity: 1;
  position: absolute;
  left: 35%;
  right: 0px;
  top: 0px;
  margin-left: auto;
  margin-right: auto;
  z-index: 99999999;
  max-width: 720px;
  max-height: 720px;

  .top-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    padding-top: 8px;
    padding-left: 8px;
    margin-right: 8px;
    .icon-holder {
      height: 30px;
      width: 30px;
      cursor: pointer;
      padding-top: 8px;
      svg {
        width: inherit;
        height: inherit;
        fill: white;
      }
    }
    .arrow-holder {
      height: 50px;
      width: 50px;
      cursor: pointer;
      padding-top: 8px;
      svg {
        width: inherit;
        height: inherit;
        fill: white;
      }
    }
    .buttons {
      display: flex;
      flex-direction: row;
      gap: 8px;
      margin-right: 8px;

      .icon-holder {
        height: 30px;
        width: 30px;
        padding: 5px;
        cursor: pointer;

        svg {
          width: inherit;
          height: inherit;
          fill: white;
        }
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-top: 12px;
    min-height: 100px;
    padding-left: 24px;
    padding-right: 24px;
    text-align: start;
    margin-bottom: 16px;
    p {
      color: white;
      font-family: "Inter";
      font-weight: 700;
      font-size: 3rem;
    }
    .buttons {
      display: flex;
      flex-direction: row;
      gap: 8px;

      .icon-holder {
        height: 30px;
        width: 30px;
        padding: 5px;
        cursor: pointer;

        svg {
          width: inherit;
          height: inherit;
          fill: white;
        }
      }
    }
  }
`;
